import React from 'react'
import PropTypes from 'prop-types'
import { MANAGEMENT_TYPES } from 'src/util/constants'

const ListManagementType = ({ selectManagementType, defaultValue }) => {
  const handleSelect = (e) => selectManagementType(e.target.value)

  return (
    <div id="list-management-types" className="select-wrapper">
      <select
        defaultValue={defaultValue || ''}
        id="managementType" name="managementType"
        onChange={handleSelect}
        required
      >
        <option disabled value="" hidden>___</option>
        {MANAGEMENT_TYPES.map(b =>
          <option key={b.key} value={b.key}>{b.name}</option>
        )}
      </select>
      <span className="icon icon-down" />
    </div>
  )
}

ListManagementType.propTypes = {
  selectManagementType: PropTypes.func.isRequired,
  defaultValue: PropTypes.string
}

export default ListManagementType
