import React from "react";
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import './Confirmation.sass'

function Confirmation({onConfirm, onDismiss, title, text, intl}) {
  const finalTitle = title || intl({id: "confirmation-title"});
  const finalText = text || intl({id: "confirmation-body"})
  return (
    <div className="confirmation">
      <div className="confirmation-body">
        <div className="confirmation-title">{finalTitle}</div>
        <div className="confirmation-text">{finalText}</div>
        <div className="confirmation-buttons">
          <button className="confirmation-dismiss-button" onClick={onDismiss}><FormattedMessage id="cancel" /></button>
          <button className="confirmation-button" onClick={onConfirm}><FormattedMessage id="yes" /></button>
        </div>
      </div>
    </div>
  );
}

Confirmation.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  intl: PropTypes.object
}

export default injectIntl(Confirmation)
