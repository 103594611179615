import { useModal } from '@gluedigital/modal'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { addVisit, getBusinessVisits, uploadImage } from 'src/store/actions/api'
import { useTiposVisita } from '../../../hooks/useTiposVisita'
import './UploadButton.sass'
// import './UploadImageModal'

const UploadButton = ({ locationId, visitId, type = 1, askType, ...other }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const modal = useModal()
  const tiposVisitaOptions = useTiposVisita()
  const [file, setFile] = useState(null)
  const [askExtraInfo, setAskExtraInfo] = useState(false)
  const [tiposVisita, setTiposVisita] = useState([])

  const filePickerRef = useRef(null)

  const handleCameraButtonClick = (e) => {
    e.stopPropagation()
    filePickerRef.current.click()
  }

  const handleFileSelected = async (e) => {
    const fileList = e.target.files
    setFile(fileList[0])
    if (!visitId) {
      setAskExtraInfo(true)
    } else {
      uploadFile(fileList[0])
    }
  }

  const uploadFile = async (providedFile) => {
    const image = providedFile || file
    modal.show('upload-image-modal', { isLoading: true })

    try {
      if (!visitId) {
        const visit = await dispatch(addVisit(locationId, tiposVisita))
        visitId = visit.id
      }

      const upload = await dispatch(
        uploadImage(locationId, visitId, type, image, tiposVisita)
      )
      const imageId = upload.imagen.id

      // on blu send him to visit, previously we need to update business
      // and get visits to prevent api cache.
      if (type === 3) {
        await dispatch(getBusinessVisits(locationId))
        history.push(`/location/${locationId}/history`)
      } else {
        history.push(`/location/${locationId}/photo/${imageId}`)
      }
      modal.show('upload-image-modal', { uploadError: false })
    } catch (err) {
      console.error('Error when trying to upload image:', err)
      modal.show('upload-image-modal', { uploadError: true })
    }
    setTimeout(() => {
      modal.hide('upload-image-modal')
    }, 2000)
  }

  const handleContinue = () => {
    if (tiposVisita.length < 1) {
      alert('Debes seleccionar un tipo de visita')
    } else {
      uploadFile()
      setAskExtraInfo(false)
    }
  }

  const handleCancel = () => {
    setAskExtraInfo(false)
  }

  const isSelected = (item) => {
    return tiposVisita.includes(item)
  }

  const handleToggleSelect = (item) => {
    setTiposVisita([item])
  }

  return (
    <>
      {askExtraInfo ? (
        <div className="upload-image-modal">
          <div className="content">
            <h2>Tipo de visita</h2>
            <div className="list-options">
              {tiposVisitaOptions.tiposVisitaList.map((t) => (
                <div
                  key={t.id}
                  onClick={() => handleToggleSelect(t.nombre)}
                  className={
                    isSelected(t.nombre) ? 'option selected' : 'option'
                  }
                >
                  <span>{t.nombre}</span>
                </div>
              ))}
            </div>
            <div style={{ display: 'flex', gap: 15 }}>
              <button className="cancel" onClick={handleCancel}>
                Cancelar
              </button>
              <button onClick={handleContinue}>Continuar</button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div {...other} onClick={handleCameraButtonClick}>
        <input
          className="file-picker hidden"
          ref={filePickerRef}
          type="file"
          accept="image/*"
          onChange={handleFileSelected}
        />
      </div>
    </>
  )
}

UploadButton.propTypes = {
  locationId: PropTypes.number.isRequired,
  visitId: PropTypes.number,
  type: PropTypes.number,
  askType: PropTypes.bool
}

export default UploadButton
