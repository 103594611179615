const config = {
  phoneContact: {
    text: '56060',
    number: '56060'
  },
  website: {
    text: '',
    url: 'https://www.labels.vision'
  },
  businessConnections: {
    mivending: 1,
    blu: 2,
    other: 3,
    ggcc: 4,
    informacion: 5,
    mivending_blu: 6,
  }
}
export default config
