import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams, useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import Loading from 'src/components/Loading'
import { getBusiness } from 'src/store/actions/api'

import './Poll.sass'

const base = process.env.IMAGE_HOST

const Poll = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { locationId, photoId } = useParams()

  useEffect(() => {
    if (locationId) {
      dispatch(getBusiness(locationId))
    }
  }, [dispatch, locationId])

  const business = useSelector(s => s.api.business)
  const businessValue = business && business.value
  const images = businessValue && businessValue.imagenes
  const image = images && images.length > 0 && images.find(image => image.id === +photoId)
  const isLoading = business && business.isLoading
  const style = image && { backgroundImage: 'url("' + base + image.nombre + '")' }
  const date = image && image.fecha.split(' ')[0]
  const hour = image && image.fecha.split(' ')[1]

  const handleSubmit = e => {
    e.preventDefault()
    history.push('/location/' + locationId + '/photo/' + photoId)
  }

  let headerImage = <Loading />
  if (businessValue && !isLoading) {
    headerImage =
      <div className="image" style={style}>
        <h2>
          <span>{date}</span>
          <strong>{hour}</strong>
        </h2>
      </div>
  }

  return (
    <div id="poll" className="page">
      <header id="location-header">
        <Link className="cancel link" to={'/location/' + locationId}>
          <span className="icon icon-cross" />
        </Link>
        <h1>
          <FormattedMessage id="poll.title" />
        </h1>
      </header>
      {headerImage}
      <form className="poll-list" onSubmit={handleSubmit}>
        <label htmlFor="works-correctly">
          <h3>
            <FormattedMessage id="poll.works-correctly" />
          </h3>
        </label>
        <div className="select-wrapper">
          <select id="works-correctly" required name="works" defaultValue="">
            <FormattedMessage id="choose">
              { text => <option disabled value="" hidden>{text}</option> }
            </FormattedMessage>
            <FormattedMessage id="yes">
              { text => <option value="yes">{text}</option> }
            </FormattedMessage>
            <FormattedMessage id="no">
              { text => <option value="no">{text}</option> }
            </FormattedMessage>
          </select>
          <span className="icon icon-down" />
        </div>
        <label htmlFor="visible">
          <h3>
            <FormattedMessage id="poll.visible" />
          </h3>
        </label>
        <div className="select-wrapper">
          <select id="visible" required name="visible" defaultValue="">
            <FormattedMessage id="choose">
              { text => <option disabled value="" hidden>{text}</option> }
            </FormattedMessage>
            <FormattedMessage id="yes">
              { text => <option value="yes">{text}</option> }
            </FormattedMessage>
            <FormattedMessage id="no">
              { text => <option value="no">{text}</option> }
            </FormattedMessage>
          </select>
          <span className="icon icon-down" />
        </div>
        <div className="bottom-bar">
          <button type="submit">
            <FormattedMessage id="poll.send" />
          </button>
        </div>
      </form>
    </div>
  )
}

export default Poll
