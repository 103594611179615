import PropTypes from 'prop-types'
import React from 'react'
import { useGGCC } from 'src/hooks/useGGCC'

const ListGGCCOptions = ({ selectOption, defaultValue, required = false }) => {
  const { ggccList } = useGGCC()

  if (!ggccList || ggccList.length < 1) return <></>

  return (
    <div id="list-management-types" className="select-wrapper" style={{ marginBottom: 6 }}>
      <select
        value={defaultValue || ''}
        id="ggccConnectionValue" name="ggccConnectionValue"
        onChange={selectOption}
        required={required}
      >
        <option disabled value="" hidden>___</option>
        {ggccList.map(b =>
          <option key={b.key} value={b.key}>{b.nombre}</option>
        )}
      </select>
      <span className="icon icon-down" />
    </div>
  )
}

ListGGCCOptions.propTypes = {
  selectOption: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  required: PropTypes.bool
}

export default ListGGCCOptions
