import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { registerModal, CloseModal, useModal } from '@gluedigital/modal'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import MarkdownMessage from '@gluedigital/markdown-message'

import './DeactivateLocation.sass'
import { deactivateBusiness } from '../../store/actions/api'

const DeactivateLocation = ({ barName, barId }) => {
  const modal = useModal()
  const dispatch = useDispatch()

  const handleDeactivateLocation = () => {
    dispatch(deactivateBusiness(barId))
    modal.hide()
  }

  return (
    <div className="deactivate-location">
      <h1>
        <MarkdownMessage id="deactivate-location.title" values={{ bar: barName }} />
      </h1>
      <Link className="cancel link" to="/">
        <button className="delete" onClick={handleDeactivateLocation}>
          <FormattedMessage id="yes" />
        </button>
      </Link>
      <CloseModal tagName="button">
        <FormattedMessage id="no" />
      </CloseModal>
    </div>
  )
}

DeactivateLocation.propTypes = {
  barName: PropTypes.string.isRequired,
  barId: PropTypes.number.isRequired
}

registerModal('deactivate-location', DeactivateLocation)
