import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getProducts } from 'src/store/actions/api'

export const useProducts = () => {

  const dispatch = useDispatch()

  const products = useSelector(s => s.api.products)
  const isLoaded = !!products

  useEffect(() => {
    if (!isLoaded) {
      dispatch(getProducts())
    }
  }, [dispatch, isLoaded])

  return {
    products,
    productsList: (products && products.value && products.value.listado) || []
  }
}
