import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { getBusinessWithSimilarName } from 'src/store/actions/api'

import './LocationAlreadyExistsWarning.sass'
import { usePosition } from '../../../hooks/usePosition'

const LocationAlreadyExistsWarning = ({ name }) => {
  const dispatch = useDispatch()
  const locations = useSelector(s => s.api.businessWithSimilarName)
  const position = usePosition()

  useEffect(() => {
    if (name) {
      dispatch(getBusinessWithSimilarName(name, position))
    }
  }, [name, dispatch])

  if (!name || !locations || !locations.value || locations.value.length < 1) return <></>

  return <div className="similar-locations-container">
    <h4 className="similar-locations-header">Ya existen locales similares</h4>
    <div className="similar-locations-list">
      {locations.value.map(l => (
        <div className="similar-locations-list-item" key={l.id}>
          <span className="similar-locations-list-item-name">{l.nombre}</span>
          <span className="similar-locations-list-item-address">{l.direccion}</span>
        </div>
      ))}
    </div>
  </div>
}

LocationAlreadyExistsWarning.propTypes = {
  name: PropTypes.string
}

export default LocationAlreadyExistsWarning
