import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { getBusinessConnections } from 'src/store/actions/api'

const ListBusinessConnections = ({ selectBusinessConnection, defaultValue, ...props }) => {
  const dispatch = useDispatch()

  const businessConnections = useSelector(s => s.api.businessConnections)
  const businessConnectionsList = (businessConnections && businessConnections.value && businessConnections.value.listado) || []

  useEffect(() => {
    if (!businessConnections) {
      dispatch(getBusinessConnections())
    }
  }, [dispatch, businessConnections])

  const handleSelect = (e) => {
    selectBusinessConnection(parseInt(e.target.value))
  }

  return (
    <div id="list-business-connection" className="select-wrapper">
      <select
        defaultValue={defaultValue || ''}
        id="connection" name="connection"
        onChange={handleSelect}
        {...props}
      >
        <option value="" disabled hidden>___</option>
        {businessConnectionsList && businessConnectionsList.map(b =>
          <option key={b.id} value={b.id}>{b.nombre}</option>
        )}
      </select>
      <span className="icon icon-down" />
    </div>
  )
}

ListBusinessConnections.propTypes = {
  selectBusinessConnection: PropTypes.func.isRequired,
  defaultValue: PropTypes.number
}

export default ListBusinessConnections
