import React from 'react'
import PropTypes from 'prop-types'

import './NumberSelector.sass'

const NumberSelector = ({ min = 0, max = 100, value = min, step = 1, onChange }) => {
  const handleMinus = () => {
    if (value > min) {
      const newValue = value - step
      return onChange(newValue)
    }
  }

  const handlePlus = () => {
    if (value < max) {
      const newValue = value + step
      return onChange(newValue)
    }
  }

  return (
    <div className="number-selector">
      <span className="handler minus icon icon-minus" onClick={handleMinus} />
      <span className="value">{value}</span>
      <span className="handler plus  icon icon-plus" onClick={handlePlus} />
    </div>
  )
}

NumberSelector.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func
}

export default NumberSelector
