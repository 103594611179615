import React from 'react'
import PropTypes from 'prop-types'
import { usePlaces } from 'src/hooks/usePlaces'
import { FormattedMessage, injectIntl } from 'react-intl'
import Select from 'react-select'

const ListPlaces = ({ region, onChange, value, matchName, intl }) => {

  const places = usePlaces(region)

  const placesList = places && places.placesList &&
                            places.placesList.map((mm) => ({ value: mm.id, label: mm.nombre }))

  const selected = placesList.find((m) => m.value === value)

  if ( value === undefined && matchName) {
    const p = placesList.find(p => p.label == matchName);
    if (p) {
      onChange(parseInt(p.value))
    }
  }

  const handleSelect = (e) => onChange(e.value)

  return (
    <div id="list-places">
      <Select
        className="rounded-select"
        name="place"
        value={selected || ''}
        options={placesList}
        placeholder={<FormattedMessage id="choose" />}
        onChange={handleSelect}
        required
      />
      {/* https://github.com/JedWatson/react-select/issues/3140#issuecomment-514754657) */}
      <input
        tabIndex={-1}
        autoComplete="off"
        style={{ opacity: 0, height: 0, padding: 0 }}
        defaultValue={selected || ''}
        required
      />
    </div>
  )
}

ListPlaces.propTypes = {
  region: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  matchName: PropTypes.string,
  intl: PropTypes.object // injected by injectIntl
}

export default injectIntl(ListPlaces)
