import React from 'react'
import { FormattedMessage } from 'react-intl'

const AddressLabel = ({ zipcode, place, region}) => {
    const renderItem = (label, value) => {
        return (
            <span><FormattedMessage id={'location-form.' + label} /> {value} </span>
        )
    }
    return (
        <div className="address-props">
            {zipcode && renderItem('zipcode', zipcode) }
            {place && renderItem('place', place) }
            {region && renderItem('region', region) }
        </div>
    )

}

export default AddressLabel