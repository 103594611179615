import React from 'react'
import { registerModal } from '@gluedigital/modal'
import { FormattedMessage } from 'react-intl'
import './LocationSuccessModal.sass'

const LocationSuccessModal = () => {
  return (
    <div className="location-success-modal">
      <div className="add-location" />
      <h1>
        <FormattedMessage id="location-success-modal.success-msg" />
      </h1>
    </div>
  )
}

registerModal('location-success-modal', LocationSuccessModal)
