import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl'
import ImageLoader from 'react-loading-image'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Loading from 'src/components/Loading'
import config from 'src/config'
import { useImageState } from 'src/hooks/useImageState'
import {
  createVisitComment,
  deleteVisitComment,
  getProductEvolutionTable,
  getVisitComments
} from 'src/store/actions/api'
import UploadButton from './UploadButton'

import './VisitCard.sass'

const base = process.env.IMAGE_HOST
const apiHost = process.env.API_HOST
const BLU_IMG_PATH = '../img_blu/'
const IMG_TYPE_MAP = {
  before: 1,
  after: 2,
  blu: 3
}

const BLU_CONNECTION_ID = config.businessConnections.blu
const MIVENDING_BLU_CONNECTION_ID = config.businessConnections.mivending_blu

const processDate = (str) => {
  // Format: 2019-12-12 15:15:06
  const bits = str.split(/[ :-]/).map((t) => parseInt(t, 10))
  return new Date(bits[0], bits[1] - 1, bits[2], bits[3], bits[4], bits[5], 0)
}

const icons = ['clock', 'check', 'clock', 'check']

const ImageBox = ({ type, image, locationId, visitId, disabled }) => {
  const { imageStateList } = useImageState()
  const token = useSelector((s) => s.user && s.user.token)

  const handleDownloadButtonClick = () => {
    const endpoint = type === 'blu' ? 'obtenerImagenBlu' : 'obtenerImagenOriginal'
    window.location = `${apiHost}${endpoint}/?idimage=${image.id}&token=${token}`
  }
  let style = {}
  let content

  if (image) {
    const imageState = imageStateList && imageStateList.find((s) => s.id === image.idestado)
    const icon = 'icon icon-' + (icons[image.idestado - 1] || 'cross')
    const date = processDate(image.fecha)

    let fname = image.nombre
    if (date.valueOf() >= 1576162800000) {
      // Starting on this date, all images have thumbs
      fname = fname.substr(0, fname.lastIndexOf('.')) + '_thumb.jpg'
    }
    // BLU images and thumbs are stored on different path
    const imgpath = type === 'blu' ? BLU_IMG_PATH : ''
    style = { backgroundImage: 'url("' + base + imgpath + fname + '")' }
    const url = '/location/' + locationId + '/photo/' + image.id

    content = (
      <div className="content-visit-card">
        <div className="buttons">
          {!disabled ? <UploadButton
            className="icon icon-camera"
            locationId={locationId}
            visitId={visitId}
            type={IMG_TYPE_MAP[type]}
          /> : <></>}
          <div className="icon icon-down" onClick={handleDownloadButtonClick} />
        </div>
        {type === 'blu' || disabled ? (
          <div className="image" style={style} />
        ) : (
          <Link to={url} className="image" style={style}>
            <div className="state">
              <strong>{imageState && imageState.nombre}</strong>
              <span className={icon} />
            </div>
          </Link>
        )}
      </div>
    )
  } else {
    content = (
      <div className="add-image">
        {!disabled ? <UploadButton
          className="icon icon-camera"
          locationId={locationId}
          visitId={visitId}
          type={IMG_TYPE_MAP[type]}
        /> : <></>}
      </div>
    )
  }

  return (
    <div className="image-card">
      <FormattedMessage id={'visit-card.' + type} />
      {content}
    </div>
  )
}
ImageBox.propTypes = {
  type: PropTypes.string.isRequired,
  locationId: PropTypes.number.isRequired,
  visitId: PropTypes.number.isRequired,
  image: PropTypes.object,
  disabled: PropTypes.bool
}

const VisitCard = ({ location, visit }) => {
  const locationId = location.id
  const dispatch = useDispatch()
  const date = processDate(visit.fecha)
  const [imgBefore, imgAfter] = visit.imagenes
  const imgBlu = visit.imagen_blu && visit.imagen_blu
  const token = useSelector((s) => s.user && s.user.token)
  const userId = useSelector((s) => s.user && s.user.decodedToken && s.user.decodedToken.usuario.id)
  const external = useSelector((s) => s.user && s.user.decodedToken && s.user.decodedToken.usuario.externo)

  const consumablesUrl = '/location/' + locationId + '/visit/' + visit.id + '/consumables'

  const extended = true
  const [evolution, setEvolution] = useState(null)
  const [comments, setComments] = useState([])
  const [comment, setComment] = useState('')

  const showAllDetails = useMemo(() => userId === visit.idusuario || !external, [userId, visit.idusuario, external])

  const handleCreateComment = useCallback(async () => {
    setComment('')
    await dispatch(createVisitComment(visit.id, comment))
    await dispatch(getVisitComments(visit.id)).then((res) => {
      res && setComments(res)
    })
  }, [comment, dispatch, visit.id])

  const handleDeleteComment = useCallback(
    async (id) => {
      await dispatch(deleteVisitComment(id))
      await dispatch(getVisitComments(visit.id)).then((res) => {
        res && setComments(res)
      })
    },
    [dispatch, visit.id]
  )

  useEffect(() => {
    if (locationId && visit) {
      dispatch(getProductEvolutionTable(locationId, visit.id)).then((res) => {
        res && setEvolution(res.listado)
      })
      dispatch(getVisitComments(visit.id)).then((res) => {
        res && setComments(res)
      })
    }
  }, [dispatch, locationId, visit])

  return (
    // <div className="visit-card" onClick={() => setExtended(!extended)}>
    <div className="visit-card">
      <div className="info">
        <FormattedMessage className="" id="visit-card.visit" />
        <time className="date">
          <strong>
            <FormattedDate value={date} day="2-digit" month="2-digit" year="numeric" />
          </strong>
        </time>
        <time className="hour">
          <FormattedTime value={date} hour="2-digit" minute="2-digit" />
        </time>
      </div>
      <div className={'visit-content' + (extended ? ' extended' : '')}>
        <div className="images">
          <ImageBox type="before" image={imgBefore} locationId={locationId} visitId={visit.id} disabled={!showAllDetails} />
          <ImageBox type="after" image={imgAfter} locationId={locationId} visitId={visit.id} disabled={!showAllDetails} />
        </div>
        { showAllDetails
          ? (
            <div className="images">
              <div className="button-box">
                <Link className="button" to={consumablesUrl}>
                  Consumibles
                </Link>
              </div>
              {[BLU_CONNECTION_ID, MIVENDING_BLU_CONNECTION_ID].includes(location.idconexion) ? (
                <ImageBox type="blu" image={imgBlu} locationId={locationId} visitId={visit.id} />
              ) : (
                <></>
              )}
            </div>
          ) : <></>
        }

        { showAllDetails
          ? (
            <div className="evolution">
              {!(evolution && evolution.length) ? (
                <></>
              ) : (
                <div className="table">
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <FormattedMessage id="visit-card.evolution.brand" />
                        </th>
                        <th>
                          <FormattedMessage id="visit-card.evolution.product" />
                        </th>
                        <th>
                          <FormattedMessage id="visit-card.evolution.net" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {evolution.map((e, idx) => (
                        <tr key={'evolution_' + idx}>
                          <td>{e.tabaquera}</td>
                          <td>{e.producto}</td>
                          <td>{e.ganado}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              <div className="chart">
                <ImageLoader
                  src={`${apiHost}obtenerGrafica?idlocal=${locationId}&idvisita=${visit.id}&idpanel=5&width=800&height=400&token=${token}`}
                  image={(props) => <img src={props.src} alt="grafica" />}
                  loading={() => <Loading />}
                  alt="Gráfica"
                />
              </div>
            </div>
          ) : <></> }
      </div>
      {visit.tipos_visita && visit.tipos_visita.length > 0 ? (
        <div style={{ marginBottom: 15 }}>
          <div className="comments-header">Tipo visita</div>
          <div style={{ display: 'flex', gap: 15, fontSize: 12 }}>
            {visit.tipos_visita.map((v) => (
              <span key={v}>{v}</span>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
      {showAllDetails ? (
        <div>
          <div className="comments-header">
            <FormattedMessage id="visit-card.comments" />
          </div>
          {comments && comments.length > 0 ? (
            <div className="comments-list">
              {comments.map((comment) => (
                <div className="comment" key={comment.id}>
                  <div className="comment-info">
                    <div className="comment-info-user">{comment.usuario}</div>
                    {comment.idusuario === userId ? (
                      <span role="button" className="comment-delete" onClick={() => handleDeleteComment(comment.id)}>
                        Eliminar comentario
                      </span>
                    ) : (
                      <></>
                    )}
                    <div className="comment-info-date">{comment.fecha}</div>
                  </div>
                  <div className="comment-comment">{comment.comentario}</div>
                </div>
              ))}
            </div>
          ) : (
            <div className="comments-no-comments">
              <FormattedMessage id="visit-card.comments.no-comments" />
            </div>
          )}
          <div className="comments-add">
            <textarea
              rows="3"
              onChange={(ev) => setComment(ev.target.value)}
              value={comment}
              className="comments-textarea"
            />
            <div className="comments-add-button-container">
              <button onClick={handleCreateComment}>Añadir</button>
            </div>
          </div>
        </div>
      ) : <></>}
    </div>
  )
}

VisitCard.propTypes = {
  location: PropTypes.object.isRequired,
  visit: PropTypes.object.isRequired,
  src: PropTypes.string,
}

export default VisitCard
