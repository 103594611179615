import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getBusinessConnections } from 'src/store/actions/api'

export const useBusinessConnections = () => {

  const dispatch = useDispatch()

  const businessConnections = useSelector(s => s.api.businessConnections)
  const isLoaded = !!businessConnections

  useEffect(() => {
    if (!isLoaded) {
      dispatch(getBusinessConnections())
    }
  }, [dispatch, isLoaded])

  return {
    businessConnections,
    businessConnectionsList: (businessConnections && businessConnections.value && businessConnections.value.listado) || []
  }
}
