import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Select from 'react-select'

import { useVentas } from 'src/hooks/useVentas'

const ListVentas = ({ onChange, value }) => {
  const ventas = useVentas()

  const ventasList = ventas && ventas.ventasList &&
    ventas.ventasList.map((mm) => ({ value: mm.id, label: mm.nombre }))

  const selected = ventasList.find((m) => m.value === value)

  const handleSelect = (e) => onChange(e.value)

  return (
    <div id="list-venta">
      <Select
        className="rounded-select"
        name="venta"
        value={selected || ''}
        options={ventasList}
        placeholder={<FormattedMessage id="choose" />}
        onChange={handleSelect}
        required
      />
      {/* https://github.com/JedWatson/react-select/issues/3140#issuecomment-514754657) */}
      <input
        tabIndex={-1}
        autoComplete="off"
        style={{ opacity: 0, height: 0, padding: 0 }}
        defaultValue={selected || ''}
        required
      />
    </div>
  )
}

ListVentas.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number
}

export default ListVentas
