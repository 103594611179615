import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getMachineBrands } from 'src/store/actions/api'

export const useMachineBrands = () => {

  const dispatch = useDispatch()

  const machineBrands = useSelector(s => s.api.machineBrands)
  const isLoaded = !!machineBrands

  useEffect(() => {
    if (!isLoaded) {
      dispatch(getMachineBrands())
    }
  }, [dispatch, isLoaded])

  return {
    machineBrands,
    machineBrandsList: (machineBrands && machineBrands.value && machineBrands.value.listado) || []
  }
}
