import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { dailyActivities } from 'src/util/selectors'
import { getDailyActivities } from 'src/store/actions/api'
import BusinessCard from 'src/components/BusinessCard'

import Loading from 'src/components/Loading'

import './Activities.sass'

const Activities = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getDailyActivities())
  }, [dispatch])
  const activities = useSelector((s) => s.api.dailyActivities)
  const activitiesList = useSelector(dailyActivities)

  const isLoading = activities && activities.isLoading

  let content = <Loading />
  if (!isLoading) {
    content =
      activitiesList &&
      activitiesList.map((a) => {
        return <BusinessCard deactivated={a.desactivado} key={a.id} values={a} toHistory isPending />
      })
  }

  return (
    <div id="activities" className="page">
      <header id="activities-header">
        <div className="links">
          <Link className="cancel link" to="/">
            <span className="icon icon-left" />
            <FormattedMessage id="cancel" />
          </Link>
        </div>
        <h1>
          <FormattedMessage id="activities.title" />
        </h1>
      </header>
      <section className="activities-list">
        <h2>
          <FormattedMessage id="activities.locations" />
        </h2>
        {content}
      </section>
    </div>
  )
}

export default Activities
