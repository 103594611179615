import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { login } from 'src/store/actions/api'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import config from 'src/config'
import Loading from 'src/components/Loading'

import './Login.sass'

const Login = () => {
  const dispatch = useDispatch()
  const [status, setStatus] = useState()

  const handleSubmit = e => {
    e.preventDefault()
    if (status === 'loading') return // No repeated requests
    const username = e.target.username.value
    const password = e.target.password.value
    setStatus('loading')
    dispatch(login(username, password))
      .catch(() => setStatus('error'))
  }
  const loginForm =
    <form onSubmit={handleSubmit} className={status}>
      <label>
        <FormattedMessage id="username" />
        <span className="icon icon-email" />
        <input
          type="text"
          name="username"
          placeholder="..."
          autoComplete="username"
        />
      </label>
      <label>
        <FormattedMessage id="password" />
        <span className="icon icon-lock" />
        <input
          type="password"
          name="password"
          placeholder="..."
          autoComplete="current-password"
        />
      </label>
      <p className="contact">
        {status === 'error' &&
          <span className="error-message">
            <FormattedMessage id="login.error" />
          </span>
        }
        <a href={'tel:' + config.phoneContact.number}>
          <MarkdownMessage id="login.forgot-password" />
        </a>
      </p>
      <button type="submit">
        <FormattedMessage id="login" />
      </button>
    </form>

  return (
    <div id="login" className="page">
      <header id="login-header">
        <div className="icon" />
        <div className="logo" />
      </header>
      <section id="login-content">
        <h1>
          <FormattedMessage id="login.title" />
        </h1>
        {status !== 'loading' ? loginForm : <Loading />}
      </section>
    </div>
  )
}

export default Login
