import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { useDispatch } from 'react-redux'
import { getBusinessVisits } from 'src/store/actions/api'

import Loading from 'src/components/Loading'
import ConsumablesForm from './ConsumablesForm'

import './Consumables.sass'

const Consumables = () => {

  const dispatch = useDispatch()
  const params = useParams()
  const locationId = parseInt(params.locationId)
  const visitId = parseInt(params.visitId)

  const [myMaterials, setMyMaterials] = useState([])

  const [hasErrorLoading, setHasErrorLoading] = useState(null)
  const isLoading = (hasErrorLoading === null)

  useEffect(() => {
    if (locationId && visitId) {
      dispatch(getBusinessVisits(locationId))
        .then((res) => {
          const currentVisit = res.visitas && res.visitas.find(v => v.id === visitId)
          setHasErrorLoading(!currentVisit)
          setMyMaterials(currentVisit && currentVisit.material)
        })
        .catch(() => {
          setHasErrorLoading(true)
        })
    }
  }, [dispatch, locationId, visitId])

  const errorMsg = (
    <div className="consumables-error-msg">
      <FormattedMessage id="consumables.api-error" />
    </div>
  )

  return (
    <div id="consumables" className="page">
      <header id="location-header">
        <Link className="cancel link" to={'/location/' + locationId + '/history'}>
          <span className="icon icon-cross" />
        </Link>
        <h1>
          <FormattedMessage id="consumables.title" />
        </h1>
      </header>
      <div id="consumables-photo">
        <div className="icon" />
        <div className="logo" />
      </div>
      {isLoading ? <Loading /> : hasErrorLoading ? errorMsg
        : <ConsumablesForm visitId={visitId} locationId={locationId} materials={myMaterials} />}
    </div>
  )
}

export default Consumables
