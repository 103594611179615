import jwtDecode from 'jwt-decode'

const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case 'loadUserData':
      return action.payload
    case 'login/success':
      return {
        username: action.meta.username,
        token: action.payload.token,
        decodedToken: jwtDecode(action.payload.token)
      }
    case 'logout':
      return initialState
    default:
      if (action.type.endsWith('/error') && action.status === 401) {
        // Auth failure in some call. Token must be expired. Log out.
        return initialState
      }
      if (action.type.endsWith('/success') && action.payload && action.payload.token) {
        // Successful API call. Update token.
        return { ...state, token: action.payload.token }
      }
      return state
  }
}
