import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import TabContent from './TabContent'
import { useLocation, useHistory } from 'react-router-dom'

import './Tabs.sass'

const Tabs = ({ location }) => {

  let infoClassName = 'tab info'
  let historyClassname = 'tab history'
  const history = useHistory()
  const { pathname } = useLocation()

  if (pathname === '/location/' + location) {
    infoClassName += ' selected'
  } else {
    historyClassname += ' selected'
  }

  const infoClick = () => {
    history.push('/location/' + location)
  }
  const historyClick = () => {
    history.push('/location/' + location + '/history')
  }

  return (
    <React.Fragment>
      <header className="tabs">
        <div className={infoClassName} onClick={() => infoClick()}>
          <FormattedMessage id="location.info" />
        </div>
        <div className={historyClassname} onClick={() => historyClick()}>
          <FormattedMessage id="location.history" />
        </div>
      </header>
      <TabContent />
    </React.Fragment>
  )
}

Tabs.propTypes = {
  location: PropTypes.string.isRequired
}

export default Tabs
