import { useEffect } from 'react'
import { useSelector, useDispatch, useStore } from 'react-redux'

import { getImageState } from 'src/store/actions/api'

export const useImageState = () => {

  const dispatch = useDispatch()

  const imageState = useSelector(s => s.api.imageState)
  const isLoaded = !!imageState

  const store = useStore()

  useEffect(() => {
    if (!isLoaded) {
      // Double-check that it was not requested already
      if (!store.getState().api.imageState) {
        dispatch(getImageState())
      }
    }
  }, [store, dispatch, isLoaded])

  return {
    imageState,
    imageStateList: (imageState && imageState.value && imageState.value.listado) || []
  }
}
