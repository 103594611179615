import React from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'

import Loading from 'src/components/Loading'
import VisitCard from './VisitCard'

const History = () => {

  const { locationId } = useParams()

  const business = useSelector(s => s.api.business)
  const isLoading =
    business && business.isLoading && (!business.oldValue || business.oldValue.local.id !== parseInt(locationId))
  const businessValue = business && (business.value || business.oldValue)

  let content = <Loading />
  if (businessValue && !isLoading) {
    if (businessValue.visitas && businessValue.visitas.length > 0) {
      content =
        <div className="history-list">
          {businessValue && businessValue.visitas.map(visit => {
            return <VisitCard key={visit.id} visit={visit} location={businessValue.local} />
          })}
        </div>
    } else {
      content =
        <div className="history-list">
          <h3 className="no-activity">
            <FormattedMessage id="location.history.no-activity" />
          </h3>
        </div>
    }
  }

  return (
    <div id="tab-history">
      {content}
    </div>
  )
}

export default History
