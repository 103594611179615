import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import './OfflineNotice.sass'

const OfflineNotice = () => {
  const isOffline = useSelector(s => s.api.offline)
  if (!isOffline) return false
  return (
    <div id="offline-notice">
      <img src="/images/offline.svg" alt="Offline" />
      <FormattedMessage id="offline-mode" />
    </div>
  )
}

export default OfflineNotice
