import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getGGCC } from 'src/store/actions/api'

export const useGGCC = () => {

  const dispatch = useDispatch()

  const ggcc = useSelector(s => s.api.ggcc)
  const isLoaded = !!ggcc

  useEffect(() => {
    if (!isLoaded) {
      dispatch(getGGCC())
    }
  }, [dispatch, isLoaded])

  return {
    ggcc,
    ggccList: (ggcc && ggcc.value && ggcc.value.listado) || []
  }
}
