import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { getBusinessTypes } from 'src/store/actions/api'

const ListBusinessTypes = ({ selectBusinessType, defaultValue }) => {
  const dispatch = useDispatch()

  const businessTypes = useSelector(s => s.api.businessTypes)
  const businessTypesList = (businessTypes && businessTypes.value && businessTypes.value.listado) || []

  useEffect(() => {
    if (!businessTypes) {
      dispatch(getBusinessTypes())
    }
  }, [dispatch, businessTypes])

  const handleSelect = (e) => selectBusinessType(e.target.value)

  return (
    <div id="list-business-types" className="select-wrapper">
      <select
        defaultValue={defaultValue || ''}
        id="business" name="business"
        onChange={handleSelect}
        required
      >
        <option disabled value="" hidden>___</option>
        {businessTypesList && businessTypesList.map(b =>
          <option key={b.id} value={b.id}>{b.nombre}</option>
        )}
      </select>
      <span className="icon icon-down" />
    </div>
  )
}

ListBusinessTypes.propTypes = {
  selectBusinessType: PropTypes.func.isRequired,
  defaultValue: PropTypes.number
}

export default ListBusinessTypes
