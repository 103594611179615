import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getVentas } from 'src/store/actions/api'

export const useVentas = () => {

  const dispatch = useDispatch()

  const ventas = useSelector(s => s.api.ventas)
  const isLoaded = !!ventas

  useEffect(() => {
    if (!isLoaded) {
      dispatch(getVentas())
    }
  }, [dispatch, isLoaded])

  return {
    ventas,
    ventasList: (ventas && ventas.value && ventas.value.listado) || []
  }
}
