import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
import { ModalLink } from '@gluedigital/modal'
import LocationForm from './LocationForm/LocationForm'
import { getBusinessVisits } from 'src/store/actions/api'
import Tabs from './Tabs'

import './DeactivateLocation'
import './Location.sass'

const Location = () => {
  const dispatch = useDispatch()
  const { locationId } = useParams()

  useEffect(() => {
    if (locationId) {
      dispatch(getBusinessVisits(locationId))
    }
  }, [dispatch, locationId])

  const business = useSelector((s) => s.api.business)
  const businessValue = business && (business.value || business.oldValue)
  const local = businessValue && businessValue.local.id === parseInt(locationId) && businessValue.local
  const barName = local && local.nombre
  const barId = local && local.id

  const [editMode, setEditMode] = useState(false)

  let icon = 'icon icon-coffee'
  if (local && local.idtipo === 1) {
    icon = 'icon icon-bar'
  } else if (local && local.idtipo === 2) {
    icon = 'icon icon-fork'
  } else if (local && local.idtipo === 3) {
    icon = 'icon icon-spades'
  }

  let locationHeader = (
    <header id="location-header">
      <Link className="cancel link" to="/">
        <span className="icon icon-left" />
      </Link>
      <h1>{barName}</h1>
      <div className="buttons">
        {local && !local.desactivacion_solicitada && (
          <ModalLink tagName="div" modal="deactivate-location" barId={barId} barName={barName}>
            <div className="icon icon-bin" />
          </ModalLink>
        )}
        { local && local.desactivacion_solicitada > 0 && (
          <div style={{padding: 5}}>Baja solicitada</div>
        )}
        <div className="icon icon-pen" onClick={() => setEditMode(true)} />
      </div>
    </header>
  )

  if (editMode) {
    locationHeader = (
      <header id="location-header" className="edit-mode">
        <div className="link" onClick={() => setEditMode(false)}>
          <span className="icon icon-cross" />
          <FormattedMessage id="editing" />
        </div>
        <h1>
          {barName}
          <span className={icon} />
        </h1>
      </header>
    )
  }
  return (
    <div id="location" className="page">
      {locationHeader}
      <section id="location-content">
        {editMode ? <LocationForm business={local} setEditMode={setEditMode} /> : <Tabs location={locationId} />}
      </section>
    </div>
  )
}

export default Location
