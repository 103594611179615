import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import MarkdownMessage from '@gluedigital/markdown-message'
import { Link } from 'react-router-dom'
import {
  pendingBusinessLength,
  dailyActivitiesLength
} from 'src/util/selectors'

import './Header.sass'

const Header = ({
  isTooFarAway,
  setFilter,
  setListFilter,
  listFilter,
  filter,
  mapMode
}) => {
  const pendingCount = useSelector(pendingBusinessLength)
  const activitiesCount = useSelector(dailyActivitiesLength)

  let title
  if (pendingBusinessLength && dailyActivitiesLength) {
    title = (
      <div className="messages">
        <Link to="/pending">
          <MarkdownMessage
            id="header-title.pending"
            values={{ n: pendingCount }}
          />
        </Link>
        <Link to="/activities">
          <MarkdownMessage
            id="header-title.activities"
            values={{ n: activitiesCount }}
          />
        </Link>
      </div>
    )
  } else if (isTooFarAway) {
    title = <MarkdownMessage id="header-title.zoom" />
  } else {
    title = <MarkdownMessage id="header-title.move" />
  }

  return (
    <header id="map-header" className={!mapMode ? 'no-shadow' : ''}>
      <h1 className="locations">{title}</h1>
      <Link className="icon icon-settings" to="/account" />
      <input
        type="text"
        value={mapMode ? filter : listFilter}
        name="filter"
        onChange={(e) =>
          mapMode ? setFilter(e.target.value) : setListFilter(e.target.value)
        }
      />
    </header>
  )
}

Header.propTypes = {
  isTooFarAway: PropTypes.bool.isRequired,
  setFilter: PropTypes.func,
  filter: PropTypes.string,
  setListFilter: PropTypes.func,
  listFilter: PropTypes.string,
  mapMode: PropTypes.bool
}

export default Header
