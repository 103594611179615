import PropTypes from 'prop-types'
import React from 'react'
import { usePymoOptions } from '../../../hooks/usePymoOptions'

const ListPymoOptions = ({ selectOption, defaultValue, required = false }) => {
  const { pymoOptionsList } = usePymoOptions()

  if (!pymoOptionsList || pymoOptionsList.length < 1) return <></>

  return (
    <div id="list-management-types" className="select-wrapper" style={{ marginBottom: 6 }}>
      <select
        value={defaultValue || ''}
        id="pymoOptionValue"
        name="pymoOptionValue"
        onChange={selectOption}
        required={required}
      >
        <option disabled value="" hidden>
          ___
        </option>
        {pymoOptionsList.map((b) => (
          <option key={b.id} value={b.id}>
            {b.name}
          </option>
        ))}
      </select>
      <span className="icon icon-down" />
    </div>
  )
}

ListPymoOptions.propTypes = {
  selectOption: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  required: PropTypes.bool
}

export default ListPymoOptions
