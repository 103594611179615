import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './ErrorNotice.sass'
import { removeError } from '../store/actions/api'

const ErrorNotice = () => {
  const error = useSelector(s => s.api.error)
  const dispatch = useDispatch()

  if (!error) return false
  return (
    <div id="error-notice">
      {error}
      <span className="icon icon-cross" onClick={() => dispatch(removeError())} />
    </div>
  )
}

export default ErrorNotice
