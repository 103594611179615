import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getTrOptions } from 'src/store/actions/api'

export const useTrOptions = () => {
  const dispatch = useDispatch()

  const trOptions = useSelector((s) => s.api.troptions)
  const isLoaded = !!trOptions

  useEffect(() => {
    if (!isLoaded) {
      dispatch(getTrOptions())
    }
  }, [dispatch, isLoaded])

  return {
    trOptions,
    trOptionsList: (trOptions && trOptions.value && trOptions.value.listado) || []
  }
}
