import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
import { useModal } from '@gluedigital/modal'
import useImageDetails from 'src/hooks/useImageDetails'
import { useProducts } from 'src/hooks/useProducts'

import './PositionModal'
import './EditPhoto.sass'

const base = process.env.IMAGE_HOST

const EditPhoto = () => {
  const modal = useModal()
  const params = useParams()
  const locationId = parseInt(params.locationId)
  const photoId = parseInt(params.photoId)

  const [labels, setLabels] = useState([])

  const products = useProducts()
  const productsList = products && products.productsList

  const image = useImageDetails(locationId, photoId)

  const imageUrl = image ? base + image.nombre : null
  const style = image ? { backgroundImage: 'url(' + imageUrl + ')' } : {}

  const hadlePositionModal = e => {
    const rect = e.target.getBoundingClientRect()
    const cursorX = e.clientX - rect.left
    const cursorY = e.clientY - rect.top
    setLabels([...labels, { x: cursorX, y: cursorY }])
    modal.show('position-modal', { coords: { x: cursorX, y: cursorY }, productsList: productsList })
  }

  return (
    <div id="edit-photo" className="page">
      <header id="edit-header">
        <div className="links">
          <Link className="cancel link" to="./">
            <span className="icon icon-left" />
          </Link>
          <h1>
            {image && image.fecha}
          </h1>
        </div>
      </header>
      <section id="edit-content">
        <div className="explanation">
          <FormattedMessage id="edit-photo-text" />
        </div>
        <div onClick={hadlePositionModal} className="image" style={style}>
          {labels && labels.map((l, i) => {
            const labelPosition = { top: l.y, left: l.x }
            return (
              <div className="label" style={labelPosition} key={i}> {l.x + ' ' + l.y} </div>
            )
          })}
        </div>
        <div className="bottom-bar">
          <button disabled >
            <FormattedMessage id="save" />
          </button>
        </div>
      </section>
    </div>
  )
}

export default EditPhoto
