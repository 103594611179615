import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import Select from 'react-select'
import { useTrOptions } from '../../../hooks/useTrOptions'

const DOMESTIC_ID = 1

const ListTrOptions = ({ selectOption, defaultValue, estanco }) => {
  const { trOptionsList } = useTrOptions()
  const isTr = useMemo(() => estanco && estanco.tr > 0, [estanco])

  if (!trOptionsList || trOptionsList.length < 1) return <></>

  if (!isTr && (!defaultValue || defaultValue.length < 1)) {
    selectOption([DOMESTIC_ID])
  }

  const selected = trOptionsList
    .filter((b) => defaultValue && defaultValue.includes(b.id))
    .map((b) => ({ value: b.id, label: b.name }))
  const trOptions = trOptionsList.map((b) => ({ value: b.id, label: b.name }))

  return (
    <div id="list-tr-options" style={{ marginBottom: 6 }}>
      <Select
        value={selected || []}
        styles={{
          control: (base, state) => ({
            ...base,
            borderRadius: 50
          })
        }}
        id="trOptionValue"
        name="trOptionValue"
        onChange={(item) => selectOption(item.map((i) => i.value))}
        options={trOptions}
        required
        isMulti
        isDisabled={!isTr}
      />
    </div>
  )
}

ListTrOptions.propTypes = {
  selectOption: PropTypes.func.isRequired,
  defaultValue: PropTypes.array,
  estanco: PropTypes.object
}

export default ListTrOptions
