import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import Select from 'react-select'

import { useRegions } from 'src/hooks/useRegions'

const ListRegions = ({ onChange, value, matchName }) => {

  const regions = useRegions()
  
  const regionsList = regions && regions.regionsList &&
                              regions.regionsList.map((mm) => ({ value: mm.id, label: mm.nombre }))

  const selected = regionsList.find((m) => m.value === value)

  if ( value === undefined && matchName) {
    const r = regionsList.find(r => r.label == matchName);
    if (r) {
      //value = r.id
      onChange(parseInt(r.value))
    }
  }
  const handleSelect = (e) => onChange(e.value)

  return (
    <div id="list-region">
      <Select
        className="rounded-select"
        name="region"
        value={selected || ''}
        options={regionsList}
        placeholder={<FormattedMessage id="choose" />}
        onChange={handleSelect}
        required
      />
      {/* https://github.com/JedWatson/react-select/issues/3140#issuecomment-514754657) */}
      <input
        tabIndex={-1}
        autoComplete="off"
        style={{ opacity: 0, height: 0, padding: 0 }}
        defaultValue={selected || ''}
        required
      />
    </div>
  )
}

ListRegions.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  matchName: PropTypes.string,
}

export default ListRegions
