import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Link, useHistory } from 'react-router-dom'
import MarkdownMessage from '@gluedigital/markdown-message'
import { logout } from 'src/store/actions/api'
import config from 'src/config'

import './Account.sass'

const Account = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleLogout = e => {
    e.preventDefault()
    dispatch(logout())
    history.push('/')
  }
  const name = useSelector(s => s.user.username)
  return (
    <div id="account" className="page">
      <header id="account-header">
        <Link className="icon icon-left" to="/" />
        <h1>
          <FormattedMessage id="account.title" />
        </h1>
      </header>
      <section id="account-content">
        <h2 className="name">
          {name}
        </h2>
        <p className="contact">
          <a href={'tel:' + config.phoneContact.number}>
            <MarkdownMessage id="account.contact" values={{ n: config.phoneContact.text }} />
          </a>
        </p>
        <p className="web">
        <a target="_blank" href={config.website.url}>
            { config.website.text }
          </a>
        </p>
      </section>
      <footer id="account-footer">
        <button onClick={handleLogout}>
          <FormattedMessage id="logout" />
        </button>
      </footer>
    </div>
  )
}

export default Account
