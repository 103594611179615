import React from 'react'
import PropTypes from 'prop-types'
import { useMachineModels } from 'src/hooks/useMachineModels'
import { FormattedMessage, injectIntl } from 'react-intl'
import Select from 'react-select'

const ListMachineModels = ({ machineBrand, onChange, value, intl }) => {

  const machineModels = useMachineModels(machineBrand)

  const machineModelsList = machineModels && machineModels.machineModelsList &&
                            machineModels.machineModelsList.map((mm) => ({ value: mm.id, label: mm.nombre }))

  const selected = machineModelsList.find((m) => m.value === value)

  const handleSelect = (e) => onChange(e.value)

  return (
    <div id="list-machine-models">
      <Select
        className="rounded-select"
        name="machine-model"
        value={selected || ''}
        options={machineModelsList}
        placeholder={<FormattedMessage id="choose" />}
        onChange={handleSelect}
        required
      />
      {/* https://github.com/JedWatson/react-select/issues/3140#issuecomment-514754657) */}
      <input
        tabIndex={-1}
        autoComplete="off"
        style={{ opacity: 0, height: 0, padding: 0 }}
        defaultValue={selected || ''}
        required
      />
    </div>
  )
}

ListMachineModels.propTypes = {
  machineBrand: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  intl: PropTypes.object // injected by injectIntl
}

export default injectIntl(ListMachineModels)
