import React from 'react'
import { registerModal } from '@gluedigital/modal'
import PropTypes from 'prop-types'

import Progress from 'src/components/Progress'

import { FormattedMessage } from 'react-intl'
import './UploadImageModal.sass'

const UploadImageModal = ({ uploadError, isLoading }) => {

  if (isLoading) {
    return <Progress />
  }
  const imgClassName = uploadError ? 'upload-img not-valid' : 'upload-img valid'

  const messageId = 'upload-image-modal.' + (uploadError ? 'error-msg' : 'success-msg')
  const errorSubtitle = <h2><FormattedMessage id="upload-image-modal.error-msg-subtitle" /></h2>

  return (
    <div className="upload-image-modal">
      <div className={imgClassName} />
      <h1>
        <FormattedMessage id={messageId} />
      </h1>
      {uploadError && errorSubtitle}
    </div>
  )
}

UploadImageModal.propTypes = {
  uploadError: PropTypes.bool,
  isLoading: PropTypes.bool
}

UploadImageModal.defaultProps = {
  uploadError: false,
  isLoading: false
}

registerModal('upload-image-modal', UploadImageModal)
