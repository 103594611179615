import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { registerModal, CloseModal } from '@gluedigital/modal'

import Autocomplete from '@gluedigital/autocomplete'

import './PositionModal.sass'

const PositionModal = ({ coords, productsList }) => {

  return (
    <div className="position-modal">
      <header>
        <CloseModal tagName="button">
          <span className="icon icon-cross" />
          <FormattedMessage id="cancel" />
        </CloseModal>
        <button disabled className="continue-button">
          <FormattedMessage id="continue" />
          <span className="icon icon-right" />
        </button>
      </header>
      <label>
        <FormattedMessage id="product-name" />
        <div className="search-product">
          <FormattedMessage id="autocomplete">
            {text =>
              <Autocomplete
                withClear
                autoFocus
                placeholder={text}
                values={productsList}
                valueLabel="nombre"
                required
              />
            }
          </FormattedMessage>
          <button disabled><span className="icon icon-bin" /></button>
        </div>
      </label>
    </div>
  )
}

PositionModal.propTypes = {
  coords: PropTypes.object,
  productsList: PropTypes.array
}

registerModal('position-modal', PositionModal)
