import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import LocationForm from './LocationForm/LocationForm'

import './NewLocation.sass'

const NewLocation = () => {

  return (
    <div id="new-location" className="page">
      <header id="location-header">
        <div className="links">
          <Link className="cancel link" to="/">
            <span className="icon icon-left" />
            <FormattedMessage id="cancel" />
          </Link>
        </div>
        <h1>
          <FormattedMessage id="new-location.title" />
        </h1>
      </header>
      <section id="location-content">
        <LocationForm />
      </section>
    </div>
  )
}

export default NewLocation
