import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getImageProducts } from 'src/store/actions/api'

const usePhotoProducts = (locationId, photoId) => {
  const dispatch = useDispatch()

  // Check if this image was just uploaded
  const uploadedInfo = useSelector(s => s.api.uploadedImage)
  const uploadedValue = uploadedInfo && uploadedInfo.value
  const isJustUploaded = !!(uploadedValue && uploadedValue.imagen.id === photoId)

  // Check if the last req products are still valid
  const reqProductsInfo = useSelector(s => s.api.imageProducts)
  const reqProductsMeta = reqProductsInfo && reqProductsInfo.meta
  const isReqStillValid = !!(reqProductsMeta && reqProductsMeta.imageId === photoId)

  // Fetch data if needed
  useEffect(() => {
    if (photoId && !isJustUploaded && !isReqStillValid) {
      dispatch(getImageProducts(locationId, photoId))
    }
  }, [dispatch, locationId, photoId, isJustUploaded, isReqStillValid])

  // Return the correct response from what we have right now
  return isJustUploaded ? uploadedInfo : (isReqStillValid ? reqProductsInfo : {})
}

export default usePhotoProducts
