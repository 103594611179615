import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormattedDisplayName, FormattedMessage, injectIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useHistory, Prompt } from 'react-router-dom'
import { useModal } from '@gluedigital/modal'

import NumberSelector from 'src/components/NumberSelector'

import { useMaterials } from 'src/hooks/useMaterials'
import { updateMaterialQuantities } from 'src/store/actions/api'

import 'src/components/UpdateQuantitiesModal'

const ConsumablesForm = ({ visitId, locationId, materials, intl }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [quantities, setQuantities] = useState([])
  const [extraConsumables, setExtraConsumables] = useState([])

  const { materialsList: allMaterials } = useMaterials()
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const modal = useModal()

  useEffect(() => {
    if (materials) {
      setQuantities([...materials])
    }
  }, [materials])

  // Filter already added consumables from materials list
  const remainingMaterials = allMaterials
    .filter((m) => materials.findIndex((selectedMaterial) => selectedMaterial.id === m.id) === -1)
    .filter((m) => !extraConsumables.find((ec) => ec.id === m.id))

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(updateMaterialQuantities(locationId, visitId, quantities)).then(() => {
      setHasUnsavedChanges(false)
      modal.show('update-quantities-modal')
      history.push(`/location/${locationId}/history`)
      setTimeout(() => {
        modal.hide('update-quantities-modal')
      }, 1500)
    })
  }

  const handleConsumable = (consumable) => (value) => {
    const consumableData = {
      id: consumable.id,
      nombre: consumable.nombre,
      cantidad: value
    }
    const quantitiesAux = [...quantities]
    const i = quantitiesAux.findIndex((q) => q.id === consumableData.id)
    i > -1 ? (quantitiesAux[i] = consumableData) : quantitiesAux.push(consumableData)
    setQuantities(quantitiesAux)
    setHasUnsavedChanges(true)
  }

  const handleNewConsumable = (e) => {
    const id = parseInt(e.target.value)
    const toAdd = remainingMaterials.find((m) => +m.id === id)
    toAdd && setExtraConsumables([...extraConsumables, toAdd])
    setHasUnsavedChanges(true)
  }

  const formattedName = (consumable) => {
    if (allMaterials.length > 0 && allMaterials.find((m) => m.id === consumable.id)) {
      return allMaterials.find((m) => m.id === consumable.id).nombre
    }
    return consumable.nombre
  }

  const consumableEntry = (c, i) => {
    const consumableData = quantities.find((q) => q.id === c.id)
    const consumableAmount = consumableData ? consumableData.cantidad : 0
    const consumableClass = consumableAmount !== 0 ? 'consumable' : 'consumable empty'
    return (
      <div key={i} className={consumableClass}>
        <span className="consumable-name">{c && formattedName(c)}</span>
        <div style={{ textAlign: 'center' }}>
          <NumberSelector max={1000} step={c.step} value={consumableAmount} onChange={handleConsumable(c)} />
          {c.unit ? c.unit.charAt(0).toUpperCase() + c.unit.slice(1) : ''}
        </div>
      </div>
    )
  }

  const noConsumables = (
    <div className="consumables-error-msg">
      <FormattedMessage id="consumables.no-consumables" />
    </div>
  )

  return (
    <form className="consumable-list" onSubmit={handleSubmit}>
      <Prompt when={hasUnsavedChanges} message={intl.formatMessage({ id: 'unsaved-changes-prompt.message' })} />
      {materials && materials.length === 0 && extraConsumables && extraConsumables.length === 0
        ? noConsumables
        : materials && materials.map(consumableEntry)}
      {extraConsumables.map(consumableEntry)}
      {quantities && materials && (
        <div className="bottom-bar">
          <button type="submit">
            <FormattedMessage id="save" />
          </button>
          <div className="add-entry icon icon-plus1">
            <select value="" onChange={handleNewConsumable}>
              <option value="" disabled hidden>
                ...
              </option>
              {remainingMaterials &&
                remainingMaterials.map((m) => (
                  <option key={m.id} value={m.id}>
                    {m.nombre}
                  </option>
                ))}
            </select>
          </div>
        </div>
      )}
    </form>
  )
}

ConsumablesForm.propTypes = {
  visitId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  materials: PropTypes.instanceOf(Array).isRequired,
  intl: PropTypes.object // injected by injectIntl
}

export default injectIntl(ConsumablesForm)
