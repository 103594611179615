import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Loading from 'src/components/Loading'
import AreaEditor from './AreaEditor'
import ImageMapper from '../../../components/ImageMapper'
import GroupedProductList from './GroupedProductList'
import { updateImageProducts } from 'src/store/actions/api'

import useImageDetails from 'src/hooks/useImageDetails'
import usePhotoProducts from 'src/hooks/usePhotoProducts'

import './PhotoMapper.sass'

const base = process.env.IMAGE_HOST
// const NEW_AREA_HEIGHT = 120
// const NEW_AREA_WIDTH = 70
const NEW_AREA_RADIUS = 20
const MAX_SCALE_WIDTH = 768

const PhotoMapper = () => {
  const params = useParams()
  const locationId = parseInt(params.locationId)
  const photoId = parseInt(params.photoId)
  const image = useImageDetails(locationId, photoId)
  const dispatch = useDispatch()

  const productReq = usePhotoProducts(locationId, photoId)
  // const isLoading = productReq.isLoading

  const [selectedArea, setSelectedArea] = useState(null)
  const [deletedAreas, setDeletedAreas] = useState([])
  const [lastProduct, setLastProduct] = useState(null)
  const [scaleWidth, setScaleWidth] = useState(Math.min(window.innerWidth, MAX_SCALE_WIDTH))
  const [imgExpanded, setImageExpanded] = useState(false)

  const serverAreas = productReq && productReq.isSuccess && productReq.value.productos &&
      productReq.value.productos.map((p, idx) => {
        return {
          name: idx.toString(),
          shape: 'rect',
          coords: p.posicion,
          preFillColor: 'transparent',
          strokeColor: 'transparent',
          lineWidth: 5,
          data: {
            producto: {
              value: p.codigoOficial,
              label: p.nombre
            }
          }
        }
      })
  const [map, setMap] = useState({
    name: 'ProductsMap',
    areas: null
  })
  let [imageUrl, setImageUrl] = useState()
  if (!image || !(productReq && productReq.isSuccess)) return <Loading />

  // manual update imageUrl and map areas as we do not want to redraw
  imageUrl = `${base}${image.nombre}?_time=${new Date().getTime()}`
  map.areas = map.areas || serverAreas

  // When modifying existing image the info is in det_img_shape, on new uploads is imagen.det_im_shape
  // [height, width]
  // TODO: ask to send info on getBusiness().image[], but should evict cache of reducer when saving
  const imgWidth = productReq && productReq.isSuccess &&
            ((productReq.value.det_img_shape && productReq.value.det_img_shape[1]) ||
             (productReq.value.imagen.det_img_shape && productReq.value.imagen.det_img_shape[1]))

  const declarative = productReq && productReq.isSuccess && productReq.value.declarativa
  const editProduct = (area, idx) => {
    setSelectedArea(idx) // Select last item
  }

  const newProduct = evt => {
    // ImageMapper is not calling .scaleCoords()
    const scale = scaleWidth && imgWidth && imgWidth > 0 ? scaleWidth / imgWidth : 1
    const coords = { x: evt.nativeEvent.layerX / scale, y: evt.nativeEvent.layerY / scale }

    setMap(prevMap => {
      prevMap.areas.push(
        {
          name: prevMap.areas.length.toString(),
          shape: 'circle',
          coords: [
            coords.x, coords.y, NEW_AREA_RADIUS
            // coords.x - (NEW_AREA_WIDTH / 2), coords.y - (NEW_AREA_HEIGHT / 2),
            // coords.x + (NEW_AREA_WIDTH / 2), coords.y + (NEW_AREA_HEIGHT / 2)
          ],
          preFillColor: 'transparent',
          // fillColor: 'blue'
          strokeColor: 'red',
          lineWidth: 2,
          status: 'new',
          data: {
            producto: lastProduct
          }
        }
      )
      setSelectedArea(prevMap.areas.length - 1) // Select last item
      return prevMap
    })
    // Trigger react-image-mapper update
    // See https://github.com/coldiary/react-image-mapper/issues/32, as we must rnd number to refresh
    setImageUrl(`${base}${image.nombre}?${new Date().getTime()}`)

  }

  const updateArea = (areaId, data) => {
    setMap(prevMap => {
      prevMap.areas[areaId].data = data
      if (prevMap.areas[areaId].status !== 'new') prevMap.areas[areaId].status = 'updated'
      prevMap.areas[areaId].strokeColor = 'red'
      setLastProduct(data.producto)
      return prevMap
    })
    // Unselect Item
    setTimeout(() => setSelectedArea(null), 500)
  }

  const deleteArea = areaId => {
    setMap(prevMap => {
      prevMap.areas[areaId].preFillColor = 'rgba(0, 0, 0, 0.5)'
      prevMap.areas[areaId].disabled = true
      // store deleted areas to send them when submitting
      setDeletedAreas(prevDelAreas => {
        prevDelAreas.push(prevMap.areas[areaId]) // splice returns an array even we remove 1
        return prevDelAreas
      })
      return prevMap
    })
    setSelectedArea(null) // Unselect area
  }

  const save = (validated) => {
    // Only send updated or new products
    const products = map.areas.filter(a => a.status).map(a => {
      return {
        codigoOficial: a.data.producto.value,
        posicion: a.status === 'new' ? a.coords.slice(0, 2) : a.coords // on new, remove radius
      }
    })
    // append deleted areas (don't send new and then deleted ones as they where never created)
    products.push(
      ...deletedAreas
        .filter(area => area.status !== 'new')
        .map(area => ({
          codigoOficial: null,
          posicion: area.coords
        }))
    )

    dispatch(updateImageProducts(photoId, products, validated, declarative))
      .then(() => {
        // history.push(`/location/${locationId}/history`)
        // refresh current, must do full refresh to avoid cache on image
        window.location.reload()
      })
  }
  const validForm = () => {
    return !map.areas.some(area => !area.data.producto || !area.data.producto.value)
  }
  const toggleExpanded = (e) => {
    if (imgExpanded) {
      setScaleWidth(Math.min(window.innerWidth, MAX_SCALE_WIDTH))
    } else {
      setScaleWidth(imgWidth)
    }
    setImageExpanded(!imgExpanded)
  }

  return (
    <div id="photo-mapper" className="page">
      <header id="mapper-header">
        <div className="links">
          <Link className="cancel link" to={`/location/${locationId}/history`}>
            <span className="icon icon-left" />
          </Link>
          <h1>
            {image && image.fecha}
          </h1>
        </div>
        <div className="explanation">
          <FormattedMessage id="edit-photo-text" />
          { deletedAreas.length > 0 && <div style={{color: 'black', marginTop: 6}}><FormattedMessage id="validate-to-refresh-deleted" /></div> }
        </div>
        {selectedArea == null
          ? <div id="expand-icon" className={'icon icon-' + (imgExpanded ? 'minus' : 'expand')} onClick={toggleExpanded} />
          : <AreaEditor areaId={selectedArea} data={map.areas[selectedArea].data}
            onChange={updateArea} onDelete={deleteArea} />
        }
      </header>
      <section id="mapper-content">
        <div className="image-container">
          <ImageMapper src={imageUrl} map={map} width={scaleWidth} imgWidth={imgWidth}
            onClick={editProduct}
            onImageClick={newProduct}
          />
          <GroupedProductList productos={map.areas.filter(a => a.data.producto).map(a => a.data.producto)} />
        </div>
      </section>
      <div className="bottom-bar">
        <button disabled={!validForm()} onClick={() => save(1)} >
          <FormattedMessage id="validate" />
        </button>

      </div>
    </div>
  )
}

export default PhotoMapper
