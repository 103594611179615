import networkAction from '@gluedigital/network-action'
import { combineReducers } from 'redux'

const errorReducer = (state = null, action) => {
  if (action.type === 'removeError') {
    return null
  }

  if (action.type.endsWith('/error')) {
    if (action.data && action.data.codigo !== 1 && action.data.descripcion) {
      return action.data.descripcion + ` (Código error: ${action.data.codigo})`
    } else {
      return `Error no controlado en servidor (código HTTP: ${action.status})`
    }
  }
  return state
}

const offlineReducer = (state, action) => {
  if (action.type === 'offline-mode') return action.isOffline
  return state || false
}

const getImageProducts = (state, action) =>
  action.type === 'updateImageQuantities/success' ? null : networkAction.reducer('getImageProducts')(state, action)

export default combineReducers({
  nearbyBusiness: networkAction.reducer('getNearbyBusiness'),
  business: networkAction.reducer('getBusiness'),
  allBusiness: networkAction.reducer('getAllBusiness'),
  businessWithPendingTasks: networkAction.reducer('getBusinessWithPendingTasks'),
  businessWithSimilarName: networkAction.reducer('getBusinessWithSimilarName'),
  dailyActivities: networkAction.reducer('getDailyActivities'),
  uploadedImage: networkAction.reducer('uploadImage'),
  imageProducts: getImageProducts,
  // Enums:
  machineBrands: networkAction.reducer('getMachineBrands'),
  machineModels: networkAction.keyedReducer('getMachineModels', 'brandId'),
  businessTypes: networkAction.reducer('getBusinessTypes'),
  businessConnections: networkAction.reducer('getBusinessConnections'),
  imageState: networkAction.reducer('getImageState'),
  products: networkAction.reducer('getProducts'),
  materials: networkAction.reducer('getMaterials'),
  regions: networkAction.reducer('getRegions'),
  ventas: networkAction.reducer('getVentas'),
  ggcc: networkAction.reducer('getGGCC'),
  pymooptions: networkAction.reducer('getPymoOptions'),
  troptions: networkAction.reducer('getTrOptions'),
  places: networkAction.keyedReducer('getPlaces', 'regionId'),
  tiposVisita: networkAction.reducer('getTiposVisita'),
  // Misc:
  offline: offlineReducer,
  error: errorReducer
})
