import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getPymoOptions } from 'src/store/actions/api'

export const usePymoOptions = () => {
  const dispatch = useDispatch()

  const pymoOptions = useSelector((s) => s.api.pymooptions)
  const isLoaded = !!pymoOptions

  useEffect(() => {
    if (!isLoaded) {
      dispatch(getPymoOptions())
    }
  }, [dispatch, isLoaded])

  return {
    pymoOptions,
    pymoOptionsList: (pymoOptions && pymoOptions.value && pymoOptions.value.listado) || []
  }
}
