import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { pendingBusiness } from 'src/util/selectors'
import { getBusinessWithPendingTasks } from 'src/store/actions/api'
import BusinessCard from 'src/components/BusinessCard'

import Loading from 'src/components/Loading'

import './Pending.sass'

const Pending = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getBusinessWithPendingTasks())
  }, [dispatch])
  const pending = useSelector((s) => s.api.businessWithPendingTasks)
  const pendingList = useSelector(pendingBusiness)

  const isLoading = pending && pending.isLoading

  let content = <Loading />
  if (!isLoading) {
    content =
      pendingList &&
      pendingList.map((b) => {
        return <BusinessCard deactivated={b.desactivado} key={b.id} values={b} toHistory isPending />
      })
  }

  return (
    <div id="pending" className="page">
      <header id="pending-header">
        <div className="links">
          <Link className="cancel link" to="/">
            <span className="icon icon-left" />
            <FormattedMessage id="cancel" />
          </Link>
        </div>
        <h1>
          <FormattedMessage id="pending.title" />
        </h1>
      </header>
      <section className="pending-list">
        <h2>
          <FormattedMessage id="pending.locations" />
        </h2>
        {content}
      </section>
    </div>
  )
}

export default Pending
