import networkAction from '@gluedigital/network-action'
import { resizeImage } from 'src/util/image'

const apiHost = process.env.API_HOST
if (!apiHost) {
  console.error('*** Error: API_HOST is not set!')
}

const basicAuth = process.env.BASIC_AUTH
const btoa = __CLIENT__ ? window.btoa : (x) => Buffer.from(x).toString('base64')

const apiAction = networkAction.withDefaults({
  baseUrl: apiHost,
  fetchOptions: {
    headers: {
      Authorization: basicAuth && 'Basic ' + btoa(basicAuth)
    }
  },
  processBody: (body, state) => {
    if (state.user && state.user.token) body.token = state.user.token
    return body
  }
})

// ---- User ---- //

export const login = (user, pass) =>
  apiAction({
    action: 'login',
    url: 'login',
    body: {
      usuario: user,
      password: pass
    },
    meta: { username: user }
  })

export const logout = () => ({ type: 'logout' })

// ---- Business ---- //

export const getNearbyBusiness = (lat, lon, filter) =>
  apiAction({
    action: 'getNearbyBusiness',
    url: 'obtenerLocalesCercanos',
    body: { lat, lon, radio: 25, filter },
    processResponse: (data, response) => {
      if (!filter) return data

      let { listado, extraData } = data
      const curatedFilter = filter.toLowerCase()
      listado = listado.filter((l) => {
        return (
          (l.direccion && l.direccion.toLowerCase().includes(curatedFilter)) ||
          (l.nombre && l.nombre.toLowerCase().includes(curatedFilter)) ||
          (l.idstanco && l.idstanco.toString().includes(curatedFilter))
        )
      })
      return {
        ...extraData,
        listado
      }
    }
  })

export const getAllBusiness = () =>
  apiAction({
    action: 'getAllBusiness',
    url: 'obtenerLocalesProvinciasUsuario',
    body: {},
    processResponse: ({ listado }, response) => {
      return { listado }
    }
  })

export const getBusinessWithSimilarName = (name, coords) =>
  apiAction({
    action: 'getBusinessWithSimilarName',
    url: 'obtenerLocalesPorNombreSimilar',
    body: { name, coords },
    processResponse: (data) => {
      return data.listado
    }
  })

export const addBusiness = (businessData) =>
  apiAction({
    action: 'addBusiness',
    url: 'nuevoLocal',
    body: businessData
  })

export const deactivateBusiness = (businessId) =>
  apiAction({
    action: 'deactivateBusiness',
    url: 'solicitarDesactivacionLocal',
    body: { id: businessId }
  })

export const getBusiness = (businessId) =>
  apiAction({
    action: 'getBusiness',
    url: 'obtenerLocal',
    body: { id: businessId }
  })

export const getBusinessVisits = (businessId) =>
  apiAction({
    action: 'getBusiness',
    url: 'obtenerLocalVisitas',
    body: { id: businessId }
  })

export const getBusinessWithPendingTasks = () =>
  apiAction({
    action: 'getBusinessWithPendingTasks',
    url: 'obtenerLocalesConTareasPendientes',
    body: {}
  })

export const getDailyActivities = () =>
  apiAction({
    action: 'getDailyActivities',
    url: 'obtenerActividadDiaria',
    body: {}
  })

export const editBusiness = (businessId, businessData) =>
  apiAction({
    action: 'editBusiness',
    url: 'editarLocal',
    body: { id: businessId, ...businessData }
  })

// ---- Visits ---- //

export const addVisit = (businessId, tiposVisita) =>
  apiAction({
    action: 'addVisit',
    url: 'nuevaVisita',
    body: { idlocal: businessId, tiposVisita }
  })

export const deleteVisit = (id, businessId) =>
  apiAction({
    action: 'deleteVisit',
    url: 'eliminarVisita',
    body: { id, idlocal: businessId }
  })

// ---- Images ---- //

export const uploadImage = (businessId, visitId, type, image, tiposVisita) => async (dispatch) =>
  fileToBase64(await resizeImage(image)).then((b64) =>
    dispatch(
      apiAction({
        action: 'uploadImage',
        url: type === 3 ? 'enviarImagenBlu' : 'enviarImagenVisita',
        body: {
          idlocal: businessId,
          idvisita: visitId,
          idtipo: type,
          imagen: b64,
          tiposVisita
        }
      })
    )
  )

const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = (err) => reject(err)
    reader.onloadend = () => {
      const b64 = reader.result.replace(/^data:.+;base64,/, '')
      resolve(b64)
    }
    reader.readAsDataURL(file)
  })

export const updateImageQuantities = (imageId, quantities) =>
  apiAction({
    action: 'updateImageQuantities',
    url: 'actualizarImagenCantidades',
    body: { idimagen: imageId, cantidades: quantities }
  })

export const updateMaterialQuantities = (businessId, visitId, quantities) =>
  apiAction({
    action: 'updateMaterialQuantities',
    url: 'actualizarMaterial',
    body: {
      idlocal: businessId,
      idvisita: visitId,
      cantidades: quantities
    }
  })

export const updateImageProducts = (imageId, productos, validada, declarativa) =>
  apiAction({
    action: 'updateImageProducts',
    url: 'actualizarImagenProductos',
    body: { idimagen: imageId, productos: productos, validada, declarativa }
  })

// ---- Enums ---- //

export const getMachineBrands = () =>
  apiAction({
    action: 'getMachineBrands',
    url: 'fabricantes',
    body: {}
  })

export const getMachineModels = (brand) =>
  apiAction({
    action: 'getMachineModels',
    url: 'modelos',
    body: { idfabricante: brand },
    meta: { brandId: brand }
  })

export const getBusinessTypes = () =>
  apiAction({
    action: 'getBusinessTypes',
    url: 'tiposLocal',
    body: {}
  })

export const getBusinessConnections = () =>
  apiAction({
    action: 'getBusinessConnections',
    url: 'conexionesLocal',
    body: {}
  })

export const getImageState = () =>
  apiAction({
    action: 'getImageState',
    url: 'estadosImagenes',
    body: {}
  })

export const getProducts = () =>
  apiAction({
    action: 'getProducts',
    url: 'productos',
    body: {}
  })

export const getImageProducts = (businessId, imageId) =>
  apiAction({
    action: 'getImageProducts',
    // url: 'obtenerImagenCantidades',
    url: 'obtenerImagenProductos',
    body: {
      idlocal: businessId,
      idimagen: imageId
    },
    meta: { businessId, imageId }
  })

export const getTobacconistShop = (id) =>
  apiAction({
    action: 'getTobacconistShop',
    url: 'obtenerEstanco',
    body: {
      id: id
    }
  })

export const getMiVending = (id) =>
  apiAction({
    action: 'getMiVending',
    url: 'obtenerMiVending',
    body: {
      id: id
    }
  })

export const getProductEvolutionTable = (businessId, visitId) =>
  apiAction({
    action: 'getProductEvolutionTable',
    url: 'obtenerDiferenciasProductos',
    body: {
      idlocal: businessId,
      idvisita: visitId
    }
  })

export const getVisitComments = (visitId) =>
  apiAction({
    action: 'getVisitComments',
    url: 'obtenerComentariosVisita',
    body: {
      idvisita: visitId
    }
  })

export const createVisitComment = (visitId, comment) =>
  apiAction({
    action: 'createVisitComment',
    url: 'crearComentarioVisita',
    body: {
      idvisita: visitId,
      comentario: comment
    }
  })

export const deleteVisitComment = (id) =>
  apiAction({
    action: 'deleteVisitComment',
    url: 'eliminarComentarioVisita',
    body: {
      id
    }
  })

export const getMaterials = () =>
  apiAction({
    action: 'getMaterials',
    url: 'materiales',
    body: {}
  })

export const getRegions = () =>
  apiAction({
    action: 'getRegions',
    url: 'provincias',
    body: {}
  })

export const getVentas = () =>
  apiAction({
    action: 'getVentas',
    url: 'ventas',
    body: {}
  })

export const getGGCC = () =>
  apiAction({
    action: 'getGGCC',
    url: 'ggcc',
    body: {}
  })

export const getPymoOptions = () =>
  apiAction({
    action: 'getPymoOptions',
    url: 'pymooptions',
    body: {}
  })

export const getTrOptions = () =>
  apiAction({
    action: 'getTrOptions',
    url: 'troptions',
    body: {}
  })

export const getTiposVisita = () =>
  apiAction({
    action: 'getTiposVisita',
    url: 'tiposVisita',
    body: {}
  })

export const getPlaces = (region) =>
  apiAction({
    action: 'getPlaces',
    url: 'municipios',
    body: {
      idprovincia: region
    },
    meta: { regionId: region }
  })

export const removeError = () => {
  return {
    type: 'removeError'
  }
}
