import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getPlaces } from 'src/store/actions/api'

export const usePlaces = (selectedRegion) => {

  const dispatch = useDispatch()

  const places = useSelector(s => s.api.places[selectedRegion])
  const isLoaded = !!places

  useEffect(() => {
    if (selectedRegion && !isLoaded) {
      dispatch(getPlaces(selectedRegion))
    }
  }, [dispatch, selectedRegion, isLoaded])

  if (!selectedRegion) {
    return {
      places: null,
      placesList: null
    }
  }

  return {
    places,
    placesList: (places && places.value && places.value.listado) || []
  }
}
