import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import Select from 'react-select'

import { useProducts } from 'src/hooks/useProducts'

import './AreaEditor.sass'

const AreaEditor = ({ areaId, data, onChange, onDelete, intl }) => {

  const products = useProducts()
  const productsList = products && products.productsList.map((p) => ({ value: p.codigoOficial, label: p.nombre }))

  return (
    <form id="area-editor">
      <Select
        className="fill-line"
        value={data.producto}
        options={productsList}
        placeholder={<FormattedMessage id="choose" />}
        onChange={opt => {
          data.producto = opt
          onChange && onChange(areaId, data)
        }}
      />
      <div className="buttons">
        {onDelete ? <button type="button" onClick={() => onDelete && onDelete(areaId)}><span className="icon icon-bin" /></button> : <></>}
      </div>

    </form>
  )

}

AreaEditor.propTypes = {
  areaId: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  intl: PropTypes.object // injected by injectIntl
}

export default injectIntl(AreaEditor)
