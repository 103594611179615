import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getMaterials } from 'src/store/actions/api'

export const useMaterials = () => {

  const dispatch = useDispatch()

  const materials = useSelector(s => s.api.materials)
  const isLoaded = !!materials

  useEffect(() => {
    if (!isLoaded) {
      dispatch(getMaterials())
    }
  }, [dispatch, isLoaded])

  return {
    materials,
    materialsList: (materials && materials.value && materials.value.listado) || []
  }
}
