import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getRegions } from 'src/store/actions/api'

export const useRegions = () => {

  const dispatch = useDispatch()

  const regions = useSelector(s => s.api.regions)
  const isLoaded = !!regions

  useEffect(() => {
    if (!isLoaded) {
      dispatch(getRegions())
    }
  }, [dispatch, isLoaded])

  return {
    regions,
    regionsList: (regions && regions.value && regions.value.listado) || []
  }
}
