import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import Map from 'src/components/Map'
import {
  getBusinessWithPendingTasks,
  getDailyActivities,
  getAllBusiness
} from 'src/store/actions/api'
import Header from './Header'
import Footer from './Footer'
import './Home.sass'
import useDebounce from 'src/util/debounce'
import BusinessList from '../../components/BusinessList'

const Home = ({ visible }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getBusinessWithPendingTasks())
  }, [dispatch])

  useEffect(() => {
    dispatch(getDailyActivities())
  }, [dispatch])

  useEffect(() => {
    dispatch(getAllBusiness())
  }, [dispatch])

  const refresh = useCallback(() => {
    dispatch(getAllBusiness())
  }, [dispatch])

  const showInMap = useCallback((business) => {
    setShowMap(true)
    setSelectedBusiness([business])
  }, [])

  const [selectedBusiness, setSelectedBusiness] = useState([])
  const [isTooFarAway, setTooFarAway] = useState(false)
  const [filter, setFilter] = useState('')
  const [listFilter, setListFilter] = useState('')
  const [estanco, setEstanco] = useState('')
  const [showMap, setShowMap] = useState(true)
  const debouncedFilter = useDebounce(filter, 500)
  return (
    <div
      id="home"
      className="page"
      style={{ display: visible ? 'block' : 'none' }}
    >
      <Header
        isTooFarAway={isTooFarAway}
        setFilter={setFilter}
        setListFilter={setListFilter}
        filter={filter}
        listFilter={listFilter}
        mapMode={showMap}
      />
      {showMap ? (
        <Map
          selectedBusiness={selectedBusiness}
          setSelectedBusiness={setSelectedBusiness}
          setTooFarAway={setTooFarAway}
          filter={debouncedFilter}
          refresh={refresh}
        />
      ) : (
        <BusinessList
          filter={listFilter}
          refresh={refresh}
          showInMap={showInMap}
          setSelectedBusiness={setSelectedBusiness}
          setFilter={setListFilter}
          estanco={estanco}
          setEstanco={setEstanco}
        />
      )}
      <Footer
        selected={selectedBusiness}
        showMap={showMap}
        toggleMap={() => setShowMap(!showMap)}
      />
    </div>
  )
}
Home.propTypes = {
  visible: PropTypes.bool
}

export default Home
