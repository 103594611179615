import React from 'react'
import PropTypes from 'prop-types'

import { useMachineBrands } from 'src/hooks/useMachineBrands'

const ListMachineBrands = ({ onChange, value }) => {

  const { machineBrandsList } = useMachineBrands()

  const handleSelect = (e) => onChange(parseInt(e.target.value))

  return (
    <div id="list-machine-brands" className="select-wrapper">
      <select
        required
        value={value || ''}
        id="machine-brand"
        name="machine-brand"
        onChange={handleSelect}
      >
        <option disabled value="" hidden>___</option>
        {machineBrandsList && machineBrandsList.map(m =>
          <option key={m.id} value={m.id}>{m.nombre}</option>
        )}
      </select>
      <span className="icon icon-down" />
    </div>
  )
}

ListMachineBrands.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number
}

export default ListMachineBrands
