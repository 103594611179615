import React from 'react'
import { registerModal } from '@gluedigital/modal'

import { FormattedMessage } from 'react-intl'

import './UpdateQuantitiesModal.sass'

const UpdateQuantitiesModal = () => {

  return (
    <div className="update-quantities-modal">
      <h1>
        <FormattedMessage id="update-quantities-modal.success" />
      </h1>
    </div>
  )
}

registerModal('update-quantities-modal', UpdateQuantitiesModal)
