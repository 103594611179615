/**
 * Syncs the whole store contents with the sessionStorage.
 * By doing this, we can safely reload without losing any state, and no
 * problems arise from having multiple tabs open, as each has its own storage.
 */
export default ({ dispatch, getState }) => {

  let state = getState()

  return (next) => (action) => {
    if (action.type === '@@_AFTER_RENDER') {
      // After first render, read saved state
      // (We have to wait until render so SSR and first render match)
      const payload = JSON.parse(window.sessionStorage.getItem('user'))
      dispatch({ type: 'loadUserData', payload })
    }
    const ret = next(action)
    const newState = getState()
    if (state.user !== newState.user) {
      window.sessionStorage.setItem('user', JSON.stringify(newState.user))
      state = newState
    }
    return ret
  }
}
