import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Loading from 'src/components/Loading'
import config from 'src/config'
import { MANAGEMENT_TYPES } from 'src/util/constants'

import { useBusinessConnections } from 'src/hooks/useBusinessConnections'
import { useBusinessTypes } from 'src/hooks/useBusinessTypes'
import { useMachineBrands } from 'src/hooks/useMachineBrands'
import { useMachineModels } from 'src/hooks/useMachineModels'
import { useVentas } from 'src/hooks/useVentas'
import AddressLabel from './AddressLabel'

const GGCC_CONNECTION_ID = config.businessConnections.ggcc

const Info = () => {
  const { locationId } = useParams()

  const business = useSelector((s) => s.api.business)
  const businessValue = business && (business.value || business.oldValue)
  const local = businessValue && businessValue.local.id === parseInt(locationId) ? businessValue.local : null
  const isLoading = !local || (business && business.isLoading)

  const { businessTypesList } = useBusinessTypes()

  const ventas = useVentas()
  const ventasText =
    local && ventas && ventas.ventasList && ventas.ventasList.find((vl) => vl.id === local.ventasid)
      ? ventas.ventasList.find((vl) => vl.id === local.ventasid).nombre
      : 'N/A'

  const { machineBrandsList } = useMachineBrands()
  const machineBrand = local && machineBrandsList && machineBrandsList.find((elem) => elem.id === local.idfabricante)

  const { machineModelsList } = useMachineModels(machineBrand && machineBrand.id)
  const machineModel = local && machineModelsList && machineModelsList.find((elem) => elem.id === local.idmodelo)

  const { businessConnectionsList } = useBusinessConnections()
  const businessConnection =
    local && businessConnectionsList && businessConnectionsList.find((elem) => elem.id === local.idconexion)

  const getFormattedManagementType = (managementTypeKey) => {
    if (!managementTypeKey) return '-'
    const managementType = MANAGEMENT_TYPES.find((m) => m.key === managementTypeKey)
    return managementType ? managementType.name : '-'
  }

  let content = <Loading />
  if (businessValue && !isLoading) {
    content = (
      <React.Fragment>
        <div className="row">
          <h2>
            <FormattedMessage id="location-form.name" />
          </h2>
          <div className="name">{local && local.nombre}</div>
        </div>
        <div className="row">
          <h2>
            <FormattedMessage id="location-form.idLocal" />
          </h2>
          <div className="business">{local && local.id}</div>
        </div>
        <div className="row">
          <h2>
            <FormattedMessage id="location-form.managementType" />
          </h2>
          <div className="managementType">{local && getFormattedManagementType(local.tipo_gestion)}</div>
        </div>
        <div className="row">
          <h2>
            <FormattedMessage id="location-form.address" />
          </h2>
          <div className="address">
            {(local && local.direccion) || <FormattedMessage id="location-form.no-address" />}
          </div>
          {local && <AddressLabel zipcode={local.codigo_postal} place={local.municipio} region={local.provincia} />}
        </div>
        <div className="row">
          <h2>
            <FormattedMessage id="location-form.tobacconistShop" />
          </h2>
          <div className="business">{local && local.idestanco}</div>
        </div>
        <div className="row">
          <h2>
            <FormattedMessage id="location-form.connections" />
          </h2>
          <div className="business">
            {businessConnection && businessConnection.nombre} {local && local.valorconexion}
            {businessConnection && businessConnection.id === GGCC_CONNECTION_ID ? ` - ${local.ggcc}` : ''}
            {businessConnection &&
            businessConnection.id === GGCC_CONNECTION_ID &&
            local.ggcc === 'PYMO' &&
            local.pymo_option_name
              ? ` - ${local.pymo_option_name}`
              : ''}
          </div>
        </div>
        <div className="row">
          <h2>
            <FormattedMessage id="location-form.business" />
          </h2>
          <div className="business">
            {local && businessTypesList[local.idtipo - 1] && businessTypesList[local.idtipo - 1].nombre}
          </div>
        </div>
        <div className="row">
          <h2>
            <FormattedMessage id="location-form.machine" />
          </h2>
          <div className="machine">
            {local && machineBrandsList[local.idfabricante - 1] && machineBrandsList[local.idfabricante - 1].nombre}
          </div>
          <div className="model">{machineModel && machineModel.nombre}</div>
        </div>
        {local && local.estacionalidad && (
          <div className="row">
            <h2>
              <FormattedMessage id="location-form.estacionalidad" />
            </h2>
            <div className="estacionalidad">{local && local.estacionalidad}</div>
          </div>
        )}
        {local && Object.keys(local.tr_options).length > 0 && (
          <div className="row">
            <h2>
              <FormattedMessage id="location-form.trOptions" />
            </h2>
            <div className="tr_options">{local && Object.values(local.tr_options).join(', ')}</div>
          </div>
        )}
        <div className="row">
          <h2>
            <FormattedMessage id="location-form.ventas" />
          </h2>
          <div className="ventas">{local && local.ventasid && ventasText}</div>
        </div>
      </React.Fragment>
    )
  }
  return <div id="tab-info">{content}</div>
}

export default Info
