import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { ModalWrapper, Modal } from '@gluedigital/modal'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import Home from './Home'
import Account from './Account'
import Login from './Login'
import NewLocation from './Location/NewLocation'
import Location from './Location'
import PhotoMapper from './Location/PhotoMapper'
import EditPhoto from './Location/EditPhoto'
import Consumables from './Location/Consumables'
import Poll from './Location/Poll'
import Pending from './Pending'
import Activities from './Activities'
import ErrorBoundary from 'src/components/ErrorBoundary'
import OfflineNotice from 'src/components/OfflineNotice'
import ErrorNotice from 'src/components/ErrorNotice'

const App = () => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(s => s.user && !!s.user.token)
  const isHome = useRouteMatch('/')

  useEffect(() => {
    // Special action so we know that we just finished rendering
    dispatch({ type: '@@_AFTER_RENDER' })
  }, [dispatch])

  useEffect(() => {
    const handleOnline = () => dispatch({ type: 'offline-mode', isOffline: false })
    const handleOffline = () => dispatch({ type: 'offline-mode', isOffline: true })
    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)
    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [dispatch])

  if (!isLoggedIn) {
    return (
      <>
        <Login />
        <Helmet>
          <title>Labels</title>
          <meta name="description" content="Gestión de stocks en máquinas vendedoras" />
          <meta name="theme-color" content="#8B2533" />
          <link rel="manifest" href="/manifest.json" />
          <link rel="apple-touch-icon" href="/images/apple-touch-icon.png" />
          <script>
            {'if ("serviceWorker" in navigator) navigator.serviceWorker.register("/sw.js")'}
          </script>
        </Helmet>
        <OfflineNotice />
      </>
    )
  }

  return (
    <ModalWrapper>
      <main id="app">
        <ErrorBoundary>
          <Home visible={isHome.isExact} />
          <Switch>
            <Route exact path="/">
              <div />
            </Route>
            <Route path="/account">
              <Account />
            </Route>
            <Route path="/pending">
              <Pending />
            </Route>
            <Route path="/activities">
              <Activities />
            </Route>
            <Route path="/location/new">
              <NewLocation />
            </Route>
            <Route path="/location/:locationId/photo/:photoId/poll">
              <Poll />
            </Route>
            <Route path="/location/:locationId/photo/:photoId/edit">
              <EditPhoto />
            </Route>
            <Route path="/location/:locationId/photo/:photoId">
              <PhotoMapper />
            </Route>
            <Route path="/location/:locationId/visit/:visitId/consumables">
              <Consumables />
            </Route>
            <Route path="/location/:locationId">
              <Location />
            </Route>
          </Switch>
        </ErrorBoundary>
        <Modal />
        <OfflineNotice />
        <ErrorNotice />
      </main>
    </ModalWrapper>
  )
}

export default App
