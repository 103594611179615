import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { registerModal, useModal } from '@gluedigital/modal'
import { FormattedMessage } from 'react-intl'
import './LocationModal.sass'

const L = __CLIENT__ ? require('leaflet') : null
if (__CLIENT__) {
  require('leaflet-control-geocoder')
}

const tiles =
  process.env.MAPBOX_TILES_URL +
  '?access_token=' +
  process.env.MAPBOX_ACCESS_TOKEN

const Map = ({ location, setLocation, setAddress, clearSuggestions }) => {
  // Some mostly-constant things to keep between renders
  const theMap = useRef(null)
  const thePin = useRef(null)
  const initialLoc = useRef(location)

  // Initialize the map
  useEffect(() => {
    if (!theMap.current) {
      theMap.current = L.map('location-map', {
        boxZoom: false,
        keyboard: false,
        zoomSnap: 0.1,
        worldCopyJump: true
      })
      const { lat, lon, zoom } = initialLoc.current
      const center = lat ? [lat, lon] : [40, -3]
      theMap.current.setView(center, zoom || 5)
      const geocoder = L.Control.Geocoder.mapbox(process.env.MAPBOX_ACCESS_TOKEN, MAPBOX_GEOCODER_OPTIONS)

      theMap.current.on('click', e => {
        clearSuggestions()
        geocoder.reverse(e.latlng, 15, results => {
          var r = results[0]
          setAddress(r)
        })
        const z = theMap.current.getZoom()
        setLocation({
          lat: e.latlng.lat,
          lon: e.latlng.lng,
          zoom: z >= 15 ? z : z < 12 ? z + 3 : 15
        })
      })
      theMap.current.on('dragstart', e => {
        clearSuggestions()
      })

      new L.TileLayer(tiles, {}).addTo(theMap.current)
    }
  }, [clearSuggestions, setAddress, setLocation])

  // Initialize and update the pin
  useEffect(() => {
    if (!thePin.current && location.lat && location.lon) {
      const userIcon = L.divIcon({
        className: 'business-icon',
        iconSize: [30, 30]
      })
      thePin.current = L.marker([40, -3], {
        draggable: 'true',
        icon: userIcon
      })
      thePin.current.on('dragend', e => {
        const { lat, lng } = thePin.current.getLatLng()
        setLocation({ lat, lon: lng, zoom: theMap.current.getZoom() })
      })
      thePin.current.addTo(theMap.current)
    }
    if (location.lat && location.lon) {
      const center = [location.lat, location.lon]
      thePin.current.setLatLng(center)
      theMap.current.setView(center, location.zoom)
    }
  }, [location, setLocation])

  return <div id="location-map" />
}
Map.propTypes = {
  location: PropTypes.object.isRequired,
  setLocation: PropTypes.func.isRequired,
  clearSuggestions: PropTypes.func.isRequired,
  setAddress: PropTypes.func.isRequired
}

const MAPBOX_GEOCODER_OPTIONS = { geocodingQueryParams: { country: 'es', language: 'es_ES' } }

const LocationModal = ({ location, setLocation }) => {
  const modal = useModal()
  const theCoordsFakeInput = useRef(null)
  const [address, setAddress] = useState(location.direccion || '')
  const [addressProps, setAddressProps] = useState({
    postcode: location.codigo_postal || '',
    place: location.municipio || '',
    region: location.provincia || ''
  })
  const [suggestions, setSuggestions] = useState([])
  const [isAddressWritten, setIsAddressWritten] = useState(false)
  const [loadingSuggestions, setLoadingSuggestions] = useState(false)
  const [coords, setCoords] = useState({
    lat: location.lat,
    lon: location.lon,
    zoom: location.lat ? 15 : 5
  })
  const handleSubmit = e => {
    e.preventDefault()
    setLocation({
      direccion: address,
      lat: coords.lat,
      lon: coords.lon,
      codigo_postal: addressProps.postcode,
      municipio: addressProps.place,
      provincia: addressProps.region,
    })
    modal.hide()
  }

  const handleAutocenter = () => {
    navigator.geolocation.getCurrentPosition(e => {
      setCoords({ lat: e.coords.latitude, lon: e.coords.longitude, zoom: 15 })
      const geocoder = L.Control.Geocoder.mapbox(process.env.MAPBOX_ACCESS_TOKEN, MAPBOX_GEOCODER_OPTIONS)

      geocoder.reverse(
        { lat: e.coords.latitude, lng: e.coords.longitude,  },
        15,
        results => {
          var r = results[0]
          setAddress(r.name)
          setAddressProps(r.properties)
        }
      )
    })
  }

  const changeAddress = e => {
    setLoadingSuggestions(true)
    const geocoder = L.Control.Geocoder.mapbox(process.env.MAPBOX_ACCESS_TOKEN, MAPBOX_GEOCODER_OPTIONS)
    geocoder.geocode(e.target.value, results => {
      setSuggestions(results)
      setLoadingSuggestions(false)
    })
    setIsAddressWritten(true)
    setAddress(e.target.value)
    setAddressProps({})
  }

  const applySuggestion = suggestion => {
    if (suggestion && suggestion.center) {
      setCoords({
        ...coords,
        lat: suggestion.center.lat,
        lon: suggestion.center.lng
      })
    }
    setIsAddressWritten(false)
    setAddress(suggestion.name)
    setAddressProps(suggestion.properties)
    setSuggestions([])
  }

  const renderSuggestions = () => {
    if (suggestions.length === 0 && !loadingSuggestions &&
      address !== '' && isAddressWritten && address !== location.direccion) {
      return <div id="suggestions">
        <div id="not-found-suggestions">
          <FormattedMessage id="not-found-suggestions" />
        </div>
      </div>
    }
    return <div id="suggestions">
      {suggestions.map((suggestion, i) => (
        <div
          className="suggestion"
          onClick={() => applySuggestion(suggestion)}
          key={i}
        >
          {suggestion.name}
        </div>
      ))}
    </div>
  }

  return (
    <form className="location-modal" onSubmit={handleSubmit}>
      <label>
        <FormattedMessage id="location-form.address" />
        <div className="wrapper">
          <input
            autoFocus
            required
            name="address"
            value={address}
            onChange={changeAddress}
            autoComplete="off"
          />
          <div
            id="set-location"
            className="icon icon-center"
            onClick={handleAutocenter}
          />
        </div>
        <div className="suggestions-wrapper">{renderSuggestions()}</div>
      </label>
      <Map
        location={coords}
        clearSuggestions={() => setSuggestions([])}
        setLocation={setCoords}
        setAddress={(address) => {
          setIsAddressWritten(false)
          setAddress(address.name)
          setAddressProps(address.properties)
        }}
      />
      <input
        className="coords-fake-input"
        required
        name="lat"
        value={coords.lat || ''}
        onChange={() => {}}
        ref={theCoordsFakeInput}
        autoComplete="off"
      />
      <FormattedMessage id="location-modal.error.map-location">
        {messageTxt => (
          <button
            type="submit"
            onClick={() => {
              theCoordsFakeInput.current.setCustomValidity(
                !coords.lat ? messageTxt : ''
              )
            }}
          >
            <FormattedMessage id="location-modal.save" />
          </button>
        )}
      </FormattedMessage>
    </form>
  )
}

LocationModal.propTypes = {
  location: PropTypes.object.isRequired,
  setLocation: PropTypes.func.isRequired
}

registerModal('location-modal', LocationModal)
