import React from 'react'
import PropTypes from 'prop-types'

import './GroupedProductList.sass'

const GroupedProductList = ({ productos }) => {

  // Count and Group on every product change 
  const groupedProducts = []
  let lastProd
  productos.forEach(p => {
    if (p.value !== lastProd) {
      lastProd = p.value
      groupedProducts.push({
        value: p.value,
        label: p.label,
        count: 0
      })
    }
    groupedProducts[groupedProducts.length - 1].count++
  })

  return (
    <div className="product-list">
      { groupedProducts.map((item, idx) => (
        <div key={idx} className="product">
          <span className="product-name">{item.label}</span>
          <span className="product-count">{item.count}</span>
        </div>
      ))}
    </div>
  )

}

GroupedProductList.propTypes = {
  productos: PropTypes.array.isRequired
}

export default GroupedProductList
