import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBusiness } from 'src/store/actions/api'

const useImageDetails = (locationId, photoId) => {
  const dispatch = useDispatch()

  // Check if we have  image was just uploaded
  const businessInfo = useSelector(s => s.api.business)
  const businessValue = businessInfo && businessInfo.value
  const isBusinessValid = !!(businessValue && businessValue.local && businessValue.local.id === locationId)

  // Find image in business, if possible
  const images = isBusinessValid && businessValue.imagenes
  const image = images && images.find(image => image.id === photoId)
  const gotImage = !!image

  // Fetch business data if needed
  useEffect(() => {
    if (locationId && !gotImage) {
      dispatch(getBusiness(locationId))
    }
  }, [dispatch, locationId, gotImage])

  // Return the image, if we have it
  if (isBusinessValid) {
    return image
  }
  return undefined
}

export default useImageDetails
