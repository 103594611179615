import { MAX_SIZE } from 'src/util/constants'

export const scaleCanvas = (canvas, scale) => {
  const scaledCanvas = document.createElement('canvas')
  scaledCanvas.width = canvas.width * scale
  scaledCanvas.height = canvas.height * scale

  scaledCanvas
    .getContext('2d')
    .drawImage(canvas, 0, 0, scaledCanvas.width, scaledCanvas.height)

  return scaledCanvas
}

export const resizeImage = async (image) => {
  const canvas = document.createElement('canvas')
  const img = document.createElement('img')

  img.src = await new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = (e) => resolve(e.target.result)
    reader.readAsDataURL(image)
  })

  await new Promise((resolve) => {
    img.onload = resolve
  })

  canvas.width = img.width
  canvas.height = img.height
  canvas.getContext('2d').drawImage(img, 0, 0, canvas.width, canvas.height)

  let scaledCanvas
  if (canvas.width > canvas.height) {
    scaledCanvas = scaleCanvas(canvas, MAX_SIZE / img.width)
  } else {
    scaledCanvas = scaleCanvas(canvas, MAX_SIZE / img.height)
  }
  return new Promise((resolve) => {
    scaledCanvas.toBlob(resolve, 'image/jpeg', 90)
  })
}
