import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import useDebounce from 'src/util/debounce'

import { getTobacconistShop, removeError } from 'src/store/actions/api'

const SelectTobacconistShop = ({ onChange, value, ...props }) => {
  const [tobacconistShop, setTobacconistShop] = useState(value || '')
  const [tobacconistCode, setTobacconistCode] = useState(value || '')

  const debouncedSearchCode = useDebounce(tobacconistCode, 500)
  const dispatch = useDispatch()
  useEffect(() => {
    // https://www.debuggr.io/react-update-unmounted-component/
    let mounted = true
    if (debouncedSearchCode) {
      // Fire off our API call
      dispatch(getTobacconistShop(debouncedSearchCode))
        .then((result) => {
          if (mounted) {
            setTobacconistShop(result.estanco)
            onChange && onChange(result.estanco)
          }
        })
        .catch((e) => {
          // Show error and hide it after 1 second
          setTobacconistShop()
          onChange && onChange()
          setTimeout(() => dispatch(removeError()), 1000)
        })
    } else {
      setTobacconistShop()
      onChange && onChange()
    }
    return () => {
      mounted = false
    }
  }, [dispatch, debouncedSearchCode, onChange])

  return (
    <div>
      <input
        value={tobacconistCode}
        name="tobacconistShopId"
        onChange={(e) => setTobacconistCode(e.target.value)}
        {...props}
      />
      {tobacconistShop && (
        <div>
          <strong>{tobacconistShop.nombre}</strong> / <strong>{tobacconistShop.id}</strong>
          &nbsp;{tobacconistShop.direccion}, {tobacconistShop.municipio}, {tobacconistShop.provincia}
        </div>
      )}
    </div>
  )
}

SelectTobacconistShop.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any
}

export default SelectTobacconistShop
