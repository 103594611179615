import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import './ErrorBoundary.sass'

class ErrorBoundary extends React.Component {

  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static propTypes = {
    children: PropTypes.node
  }

  static getDerivedStateFromError (error) {
    return {
      hasError: true,
      details: error
    }
  }

  handleReload = () => {
    window.setTimeout(() => window.location.reload(), 0)
  }

  render () {
    if (this.state.hasError) {
      return (
        <div className="error page">
          <header>
            <div className="icon" />
            <div className="logo" />
          </header>
          <section>
            <h1><FormattedMessage id="error" /></h1>
            {__DEV__ && this.state.details &&
              <div>{this.state.details.toString()}</div>
            }
            <Link to="/" className="button" onClick={this.handleReload}>
              <FormattedMessage id="reload" />
            </Link>
          </section>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
