import React from 'react'
import PropTypes from 'prop-types'
import { ESTACIONALIDADES } from '../../../util/constants'

const ListEstacionalidad = ({ selectEstacionalidad, defaultValue }) => {
  const handleSelect = (e) => selectEstacionalidad(e.target.value)

  return (
    <div id="list-estacionalidad" className="select-wrapper">
      <select
        defaultValue={defaultValue || ''}
        id="estacionalidad"
        name="estacionalidad"
        onChange={handleSelect}
        required
      >
        <option disabled value="" hidden>
          ___
        </option>
        {ESTACIONALIDADES.map((b) => (
          <option key={b} value={b}>
            {b}
          </option>
        ))}
      </select>
      <span className="icon icon-down" />
    </div>
  )
}

ListEstacionalidad.propTypes = {
  selectEstacionalidad: PropTypes.func.isRequired,
  defaultValue: PropTypes.string
}

export default ListEstacionalidad
