import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import './BusinessCard.sass'

const BusinessCard = ({ values, isPending, toHistory, deactivated }) => {
  let icon = 'icon icon-coffee'
  if (values.idtipo === 1) {
    icon = 'icon icon-bar'
  } else if (values.idtipo === 2) {
    icon = 'icon icon-fork'
  } else if (values.idtipo === 3) {
    icon = 'icon icon-spades'
  }
  let pending = (
    <p className="validate pending">
      <span className="icon icon-bell" />
      <FormattedMessage id="card.validate" />
    </p>
  )
  if (!isPending) {
    pending = (
      <p className="not-validate pending">
        <span className="icon icon-bell" />
        <FormattedMessage id="card.not-validate" />
      </p>
    )
  }
  const destination = deactivated ? '' : '/location/' + values.id + (toHistory ? '/history' : '')
  const deactivationRequested = values.desactivacionSolicitada > 0;

  return (
    <Link to={destination} className="business-card">
      <div className={deactivated ? 'type deactivated' : (deactivationRequested ? 'type deactivation-requested' : 'type')}>
        <span className={icon} />
      </div>
      <div className="data">
        <h1>{values.nombre}</h1>
        <p className="local-id">{values.id}</p>
        <p className="address">{values.direccion}</p>
        {pending}
      </div>
    </Link>
  )
}

BusinessCard.propTypes = {
  values: PropTypes.object.isRequired,
  isPending: PropTypes.bool.isRequired,
  deactivated: PropTypes.bool,
  toHistory: PropTypes.bool
}

export default BusinessCard
