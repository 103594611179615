import React from 'react'
import { FormattedMessage } from 'react-intl'

import './Progress.sass'

const Progress = () => {
  return (
    <div id="progress">
      <strong><FormattedMessage id="progress" /></strong>
      <div className="bar">
        <div className="fill" />
      </div>
    </div>
  )
}

export default Progress
