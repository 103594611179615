export const MANAGEMENT_TYPES = [
  {
    key: 'directa',
    name: 'Directa'
  },
  {
    key: 'delegada',
    name: 'Delegada'
  }
]

export const ESTACIONALIDADES = ['No estacional', 'Cerrado Verano', 'Cerrado Invierno']

export const MAX_SIZE = 1333
