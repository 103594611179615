const sameBoringArray = []

export const allBusiness = s => {
  const all = s.api.allBusiness
  const val = all && (all.oldValue || all.value)
  return (val && val.listado) || sameBoringArray
}

export const nearbyBusiness = s => {
  const nearby = s.api.nearbyBusiness
  const val = nearby && (nearby.oldValue || nearby.value)
  return (val && val.listado) || sameBoringArray
}

export const nearbyBusinessLength = s => nearbyBusiness(s).length

export const pendingBusiness = s => {
  const pending = s.api.businessWithPendingTasks
  return (pending && pending.value && pending.value.listado) || sameBoringArray
}

export const pendingBusinessLength = s => pendingBusiness(s).length

export const dailyActivities = s => {
  const activities = s.api.dailyActivities
  return (activities && activities.value && activities.value.listado) || sameBoringArray
}

export const dailyActivitiesLength = s => dailyActivities(s).length
