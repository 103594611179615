// import React from 'react'
import './BusinessIcon.sass'

const L = __CLIENT__ ? require('leaflet') : null

const typeIcons = {
  1: 'bar',
  2: 'fork',
  3: 'spades'
}

const BusinessIcon =
  L &&
  L.Icon.extend({
    createIcon: function (oldIcon) {
      const type = this.options.business.idtipo
      const typeIcon = typeIcons[type] || 'coffee'
      const icon = 'icon icon-' + typeIcon

      const deactivated = this.options.business.desactivado
      const selected = this.options.selected
      const pending = this.options.pending
      const deactivationRequested = this.options.business.desactivacionSolicitada > 0

      let className = 'business-icon'
      if (selected) {
        className += ' selected'
      }
      if (pending) {
        className += ' pending'
      }
      if (deactivated) {
        className += ' deactivated'
      }
      if (deactivationRequested) {
        className += ' deactivation-requested'
      }

      const parentDiv = oldIcon || document.createElement('div')

      const iconDiv = document.createElement('div')
      iconDiv.classList.add('className')
      iconDiv.className = className

      const iconSpan = document.createElement('span')
      iconSpan.classList.add('className')
      iconSpan.className = icon

      iconDiv.appendChild(iconSpan)
      parentDiv.appendChild(iconDiv)

      this._setIconStyles(parentDiv, 'icon')
      return parentDiv
    },
    createShadow: () => null
  })

const businessIcon = (options) => new BusinessIcon(options)

export default businessIcon
