import React from 'react'
import { useParams, Route } from 'react-router-dom'
import History from './History'
import Info from './Info'
import UploadButton from './UploadButton'

import './UploadImageModal'

const TabContent = () => {
  const { locationId } = useParams()

  return (
    <div className="tab-content">
      <Route exact path="/location/:locationId/history">
        <History />
      </Route>
      <Route exact path="/location/:locationId">
        <Info />
      </Route>
      <UploadButton
        askType={true}
        id="add-visit"
        className="icon icon-plus1"
        locationId={parseInt(locationId)}
      />
    </div>
  )
}

export default TabContent
