import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getBusinessTypes } from 'src/store/actions/api'

export const useBusinessTypes = () => {

  const dispatch = useDispatch()

  const businessTypes = useSelector(s => s.api.businessTypes)
  const isLoaded = !!businessTypes

  useEffect(() => {
    if (!isLoaded) {
      dispatch(getBusinessTypes())
    }
  }, [dispatch, isLoaded])

  return {
    businessTypes,
    businessTypesList: (businessTypes && businessTypes.value && businessTypes.value.listado) || []
  }
}
