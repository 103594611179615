import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getMachineModels } from 'src/store/actions/api'

export const useMachineModels = (selectedBrand) => {

  const dispatch = useDispatch()

  const machineModels = useSelector(s => s.api.machineModels[selectedBrand])
  const isLoaded = !!machineModels

  useEffect(() => {
    if (selectedBrand && !isLoaded) {
      dispatch(getMachineModels(selectedBrand))
    }
  }, [dispatch, selectedBrand, isLoaded])

  if (!selectedBrand) {
    return {
      machineModels: null,
      machineModelsList: null
    }
  }

  return {
    machineModels,
    machineModelsList: (machineModels && machineModels.value && machineModels.value.listado) || []
  }
}
