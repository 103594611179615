import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getTiposVisita } from 'src/store/actions/api'

export const useTiposVisita = () => {

  const dispatch = useDispatch()

  const tiposVisita = useSelector(s => s.api.tiposVisita)
  const isLoaded = !!tiposVisita

  useEffect(() => {
    if (!isLoaded) {
      dispatch(getTiposVisita())
    }
  }, [dispatch, isLoaded])

  return {
    tiposVisita,
    tiposVisitaList: (tiposVisita && tiposVisita.value && tiposVisita.value.listado) || []
  }
}
